import React, { createContext, useContext, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Spinner from "../util/Spinner";

const FormContext = createContext(null);

export function useMultiStepForm(steps, handleSubmit) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [formDetails, setFormDetails] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const form = useRef();

  // console.log(formDetails, currentStepIndex)

  async function handleNext(e, form) {
    e.preventDefault();
    const newForm = Object.fromEntries(new FormData(form.current).entries());
    // if(newForm.teamName){
    //   const match = newForm.teamName === newForm.teamCode
    //   console.log(match)
    // }
    // console.log({ ...formDetails, ...newForm });

    if (newForm) {
      let next = nextFormStep();
      setFormDetails((prev) => {
        return { ...prev, ...newForm };
      });
      if (!next) {
        setFormLoading(true);
        const error = await handleSubmit({ ...formDetails, ...newForm });
        if (error) {
          setFormLoading(false);
        }
      }
    }
  }

  function nextFormStep() {
    if (currentStepIndex >= steps.length - 1) {
      return false;
    }
    setCurrentStepIndex((prev) => prev + 1);
    return true;
  }
  function prevFormStep() {
    if (currentStepIndex <= 0) {
      return false;
    }
    setCurrentStepIndex((prev) => prev - 1);
    return true;
  }
  const CurrentForm = () => {
    return (
      <FormContext.Provider value={{ formLoading, formDetails }}>
        <Form className="grid" ref={form} onSubmit={(e) => handleNext(e, form)}>
          {formLoading ? (
            <>
              <Spinner />
              <h2 className="mx-auto">Personalizing your Experience</h2>
            </>
          ) : (
            <>
              <div className="flex gap-4 justify-between items-center text-3xl p-3 py-2 font-medium">
                <button
                  onClick={() => prevFormStep()}
                  className={currentStepIndex <= 0 ? "opacity-50" : ""}
                  disabled={currentStepIndex <= 0 ? true : false}
                  tabIndex={-1}
                >
                  {"←"}
                </button>
                {steps.map((e, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        (currentStepIndex === i ? "font-bold" : "opacity-20") +
                        " mb-0 mt-1.5 cursor-default w-full h-1 rounded-md bg-slate-500"
                      }
                    ></div>
                  );
                })}
                <button tabIndex={-1} type="submit">
                  {"→"}
                </button>
              </div>
              {steps[currentStepIndex]}
            </>
          )}
        </Form>
      </FormContext.Provider>
    );
  };
  return {
    formDetails,
    formLoading,
    currentStepIndex,
    step: steps[currentStepIndex],
    CurrentForm,
    nextFormStep,
    prevFormStep,
  };
}

export function useFormContext() {
  return useContext(FormContext);
}
