import React, { createContext, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../firebase";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  //Listen to user auth changes and set the user
  useEffect(() => {
    const authUnsub = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setUser(null);
        setUserLoading(false);
        return;
      }
      setUser(user);
      setUserLoading(false);
    });
    return () => authUnsub();
  }, []);

  async function signIn(email, password) {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      throw err;
    }
  }
  async function createAccount(email, password) {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (err) {
      throw err;
    }
  }
  function logOut() {
    try {
      signOut(auth);
      // console.log("User Logged out!");
    } catch (err) {
      throw err;
    }
  }
  let value = { user, userLoading, signIn, createAccount, logOut };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
