import { Form, Button, Alert } from "react-bootstrap";
import React, { useRef, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { useThemeContext } from "../context/ThemeProvider";

const CreateAccount = ({ checkoutBanner = null, background = null, backgroundMobile = null }) => {
  const params = new URLSearchParams(window.location.search);
  const paramEmail = params.get("email");
  const location = useLocation();
  const [error, setError] = useState(null);
  const { user, createAccount } = useAuth();
  const email = useRef();
  const password = useRef();
  const passwordConfirm = useRef();
  const { from } = location.state || { from: { pathname: "/" } };
  const redirectURL = ".." + window.location.search;
  const signInURL = "../signin" + window.location.search;
  const { colors } = useThemeContext();

  let backgroundOptions = {
    backgroundRepeat: "repeat",
    backgroundSize: "620px",
    backgroundImage: "url(/img/default_checkout_background.png)",
  };

  if (window.innerWidth < 640) {
    if (backgroundMobile) {
      backgroundOptions.backgroundImage = `url(${backgroundMobile})`;
      backgroundOptions.backgroundRepeat = "no-repeat";
      backgroundOptions.backgroundSize = "cover";
    }
  } else if (background) {
    backgroundOptions.backgroundImage = `url(${background})`;
    backgroundOptions.backgroundRepeat = "no-repeat";
    backgroundOptions.backgroundSize = "cover";
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const em = email.current.value;
    const pw = password.current.value;
    const pwConfirm = passwordConfirm.current.value;
    setError(null);
    try {
      if (pw === pwConfirm) {
        try {
          await createAccount(em, pw);
          // navigate(from.pathname, { replace: true, relative: "route" });
          // redirect(from.pathname);
        } catch (err) {
          throw err;
        }
      } else {
        passwordConfirm.current.focus();
        throw new Error("Passowrd must match!");
      }
    } catch (err) {
      let msg = err.message ? err.message : err;
      msg = msg.replace(/Firebase: /, "");
      setError(msg);
    }
  }
  if (user) {
    return <Navigate to={redirectURL} replace />;
  }

  return (
    <div
      className="h-full grid sm:grid-cols-2 sm:grid-rows-none grid-cols-none grid-rows-[120px,1fr]"
      style={{ width: "calc(100% + 2rem", marginLeft: "-1rem" }}
    >
      <div className="sm:order-2" style={backgroundOptions}></div>
      <div className="sm:order-1 pt-3 pb-5 my-auto">
        {checkoutBanner && (
          <picture>
            <img
              className="w-full max-w-lg mb-4 mx-auto text-center text-sm"
              src={checkoutBanner}
              alt="Promotional banner featuring race details."
              title="Race to the Finish"
            ></img>
          </picture>
        )}
        <Form className="anim-card max-w-md w-full flex flex-col gap-[34px] mx-auto px-3 text-left" onSubmit={handleSubmit}>
          <p className="text-xl text-center m-0 font-bold leading-none">Let’s get started</p>
          <p className="text-sm text-center m-0">
            To register for this event, please create your My Challenge Canada account or{" "}
            <Link to={signInURL} replace={true} state={{ from: from }}>
              sign in.
            </Link>
          </p>

          <div className="flex flex-col relative mb-[20px]">
            <div
              className="flex flex-col gap-[10px] relative inset-0 rounded-md"
              style={{
                padding: "20px 28px 30px 28px",
                boxShadow: "6px 6px 0px color-mix(in srgb, #d4d4d4 42%, transparent)",
                border: "1px solid color-mix(in srgb, #d4d4d4 42%, transparent)",
              }}
            >
              <Form.Group>
                <Form.Control type="email" placeholder="Email" ref={email} defaultValue={paramEmail} required />
              </Form.Group>
              <Form.Group>
                <Form.Control type="password" placeholder="Password" ref={password} required />
              </Form.Group>
              <Form.Group>
                <Form.Control type="password" placeholder="Repeat Password" ref={passwordConfirm} required />
              </Form.Group>
              {!!error && (
                <Alert variant="danger" className="fade-down m-0">
                  {error}
                </Alert>
              )}
            </div>
            <Button
              className="mx-auto w-4/6 text-xl py-[6px] absolute -bottom-[20px] left-0 right-0 !leading-none"
              variant="primary"
              type="submit"
            >
              Create Account
            </Button>
          </div>

          <div className="flex flex-col gap-[25px]">
            <Form.Group className="flex items-center gap-3">
              <Form.Check>
                <Form.Check.Input className="!border-[#D4D4D4]" type="checkbox" name="agreedToTerms" id="agreedToTerms" required />
              </Form.Check>
              <Form.Label
                htmlFor="agreedToTerms"
                className="text-xs text-slate-400 mb-0 leading-none font-normal"
                style={{ fontFamily: "lato" }}
              >
                By selecting "Create Account," you confirm that you have read, consent, and agree to My Challenge Canada's{" "}
                <a
                  className="text-slate-400 hover:text-slate-600"
                  href="https://mychallengecanada.ca/cookies-terms-and-privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service and Privacy Policy.
                </a>{" "}
                You also agree to receive general emails and exclusive offers from My Challenge Canada. Happy running!
              </Form.Label>
            </Form.Group>
            <Form.Group className="flex flex-col justify-center mx-auto mt-0 text-center my-[10px] gap-[10px]">
              <Form.Text className="text-sm text-black">Already have an account?</Form.Text>
              <Form.Text>
                <Link to={signInURL} replace={false} state={{ from: from }} className={` px-3 py-1 rounded-md `}>
                  Log in
                </Link>
              </Form.Text>
            </Form.Group>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateAccount;
