import React, { useRef, useState } from "react";
import { useUserContext } from "../context/UserProvider";
import { Link, Navigate } from "react-router-dom";
import { Button, Form, Alert } from "react-bootstrap";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";

const ResetPassword = () => {
  const { user } = useUserContext();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userEmail, setuserEmail] = useState("");
  const [error, setError] = useState(null);
  const emailRef = useRef();
  const actionCodeSettings = {
    url: window.location.origin,
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      setuserEmail(emailRef.current.value);
      await sendPasswordResetEmail(auth, emailRef.current.value, actionCodeSettings);
      //   console.log("email sent!");
      setEmailSent(true);
    } catch (err) {
      if(err.code === "auth/user-not-found"){
        setError("Sorry. This email address is not associated with an account in our system yet.");
      }else{
        setError(true)
        console.log(err.code)
      }
      setLoading(false);
    }
  }

  if (user) {
    return <Navigate to="../" replace />;
  }
  if (emailSent) {
    return (
      <div className="anim-card max-w-md w-full flex flex-col gap-3 mx-auto px-3 text-center">
        <p className="font-semibold text-xl m-0">Email Sent!</p>
        <p className="m-0">An email has been sent to {userEmail}, follow the link there to reset your password.</p>
        <p className="m-0 mb-3">Please check your spam folder</p>
        <Link to={window.location.origin}>
          <Button className="mx-auto px-4 w-full">Continue</Button>
        </Link>
      </div>
    );
  }
  return (
    <Form className="anim-card max-w-md w-full flex flex-col gap-4 mx-auto px-3 text-left" onSubmit={handleSubmit}>
      <p className="font-semibold text-xl m-0 leading-3">Reset Password</p>
      <div>
        <Form.Control ref={emailRef} type="email" placeholder="Your email" required className="m-0" onFocus={() => setError(null)} isInvalid={error} />
        {error && typeof error === typeof "" && (
          <Alert variant="danger" className="text-sm p-2 py-1 m-0 mt-1 anim-card origin-top">
            {error}
          </Alert>
        )}
      </div>
      <Button className="mx-auto px-4 w-full" variant="primary" type="submit" disabled={loading}>
        Reset Password
      </Button>
    </Form>
  );
};

export default ResetPassword;
