// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let config = {};
// console.log(window.location.origin);
if (window.location.origin === "https://mcc-staging-4525c.web.app" || window.location.origin === "http://localhost:3000") {
  config = {
    apiKey: "AIzaSyBDBpZHhsj_yKJvaSRKV18NmTZPLlvAoYU",
    authDomain: "mcc-staging-4525c.firebaseapp.com",
    projectId: "mcc-staging-4525c",
    storageBucket: "mcc-staging-4525c.appspot.com",
    messagingSenderId: "997610486361",
    appId: "1:997610486361:web:caa4947da5844492d6cf48",
  };
} else {
  config = {
    apiKey: "AIzaSyCOecC0c7UOkJKJjrWrxryLgtkUbdF_AGE",
    authDomain: "bigtimerun-4c245.firebaseapp.com",
    projectId: "bigtimerun-4c245",
    storageBucket: "bigtimerun-4c245.appspot.com",
    messagingSenderId: "129725470599",
    appId: "1:129725470599:web:bba45fce8830bc497f6522",
    measurementId: "G-ZMS089HQZP",
  };
}

// Initialize Firebase
export const app = initializeApp(config);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app)

// connectAuthEmulator(auth, "http://localhost:9099")

export const db = getFirestore(app);
// connectFirestoreEmulator(db, "localhost", 8080)

const perf = getPerformance(app);
