import React, { useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";

export function ActivityForm({
  addTimeToRace,
  formRef,
  inputMinDateString,
  inputMaxDateString,
  inputDefaultValueString,
  isSubmitting,
  setShowActivityForm,
}) {
  const [error, setError] = useState(null);
  const hoursRef = useRef();
  const minutesRef = useRef();
  const secondsRef = useRef();
  const distanceRef = useRef();

  function onFocus(e) {
    setError(null);
    if (e.target.value === "0") {
      e.target.value = "";
    }
  }

  function blurInput(e) {
    if (e.target.value === "") {
      e.target.value = 0;
    }
  }

  if (isSubmitting) {
    return (
      <div className="m-auto p-4 bg-white rounded-md">
        <h6 className="mb-3 mt-3 text-center text-green-700">Your time has been submitted!</h6>
      </div>
    );
  }
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        if(parseFloat(distanceRef.current.value) >= 200 ){
          setError("Each activity can only be up to 200km long!");
          return;
        }
        if (hoursRef?.current?.value === "0" && minutesRef?.current?.value === "0" && secondsRef?.current?.value === "0") {
          setError("Your duration can't be 0!");
          return;
        }
        addTimeToRace(e);
      }}
      ref={formRef}
      className="anim-card m-auto p-4 bg-white rounded-md"
    >
      <h6 className="mb-3 mt-3">Enter your activity data</h6>
      <Form.Label htmlFor="activityDate" className="cursor-pointer">
        Date of Activity (ET) *
      </Form.Label>
      <Form.Group className="grid grid-cols-[1fr] gap-2 mb-3 !cursor-pointer">
        <Form.Control
          className="cursor-pointer"
          name="activityDate"
          id="activityDate"
          type="date"
          min={inputMinDateString}
          max={inputMaxDateString}
          defaultValue={inputDefaultValueString}
          required
        />
        {/* <Form.Control name="activityHour" type="number" min={1} max={12} defaultValue={inputDefaultHour} required />
          <Form.Control
            name="activityMinute"
            type="number"
            min={0}
            max={59}
            defaultValue={inputDefaultMinute}
            required
          />
          <Form.Select name="amPm" required defaultValue={currentDate.getHours() < 12 ? "AM" : "PM"}>
            <option>PM</option>
            <option>AM</option>
          </Form.Select> */}
      </Form.Group>
      <Form.Label htmlFor="distance" className="cursor-pointer">
        Distance *
      </Form.Label>
      <Form.Group className="grid grid-cols-[3fr,1fr] gap-2 mb-3">
        <Form.Control
          className="cursor-pointer"
          name="distance"
          id="distance"
          type="number"
          step={0.01}
          min={0}
          // max={200}
          onFocus={onFocus}
          required
          placeholder="Your distance in km"
          ref={distanceRef}
        />
        <Form.Select name="distanceType" required className="cursor-pointer">
          <option className="!font-sans">Km</option>
          {/* <option>Mi</option> */}
        </Form.Select>
      </Form.Group>

      <Form.Label>Duration *</Form.Label>
      <Form.Group className="grid grid-cols-3 gap-3 ">
        <Form.Label className="cursor-pointer">
          <p>Hours (hh)</p>
          <Form.Control
            className="cursor-pointer"
            name="durationHours"
            type="number"
            min={0}
            max={24}
            defaultValue={0}
            required
            onBlur={blurInput}
            onFocus={onFocus}
            ref={hoursRef}
          />
        </Form.Label>
        <Form.Label className="cursor-pointer">
          <p>Min. (mm)</p>
          <Form.Control
            className="cursor-pointer"
            name="durationMinutes"
            type="number"
            min={0}
            max={59}
            defaultValue={0}
            required
            onBlur={blurInput}
            onFocus={onFocus}
            ref={minutesRef}
          />
        </Form.Label>
        <Form.Label className="cursor-pointer">
          <p>Sec. (ss)</p>
          <Form.Control
            className="cursor-pointer"
            name="durationSeconds"
            type="number"
            min={0}
            max={59}
            defaultValue={0}
            required
            onFocus={onFocus}
            onBlur={blurInput}
            ref={secondsRef}
          />
        </Form.Label>
      </Form.Group>
      {!!error && (
        <Alert variant="danger" className="anim-card m-0 py-1">
          {error}
        </Alert>
      )}
      <p className="my-3 mt-3">This challenge works with the honor system. We trust that your activity is legit. ;)</p>
      <Form.Group className="grid grid-cols-2 gap-3">
        <Button className="w-full mt-3" variant="secondary" disabled={isSubmitting} onClick={(e) => setShowActivityForm(false)}>
          Close
        </Button>
        <Button className="w-full mt-3" type="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </Form.Group>
    </Form>
  );
}
