import React, { useRef, useState } from "react";
import { useUserContext } from "../../context/UserProvider";
import { Button } from "react-bootstrap";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { CopyIcon } from "./Icons";

function RaceTitle({ selectedRace }) {
  return (
    <div className="grid grid-cols-[65px,1fr] gap-1 items-center text-center">
      <img src={selectedRace.raceInfo?.imageUrl} alt={`${selectedRace.raceInfo?.name} Logo`} />
      <div>
        <h2 className="my-0 !text-lg font-semibold">{selectedRace.raceInfo?.name}</h2>
        <p className="my-0 text-sm">
          {selectedRace.raceInfo?.userStartDate} - {selectedRace.raceInfo?.userEndDate}
        </p>
        <p className="my-0 text-sm">
          {selectedRace?.raceInfo?.legName} - {selectedRace?.raceInfo?.distance} Km
        </p>
      </div>
    </div>
  );
}

function GarminToggle() {
  const { user, userData, linkedDevice } = useUserContext();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const activityToggleRef = useRef();
  const stepsToggleRef = useRef();
  const formRef = useRef();
  const activeToggle = userData.garminAccount.activeToggle;

  function handleInput(e) {
    const formData = Object.fromEntries(new FormData(formRef.current));
    const newActiveToggle = formData.activeToggle;
    if (newActiveToggle !== activeToggle) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }

  async function handleSave(e) {
    e.preventDefault();
    setShowSaveButton(false);
    const formData = Object.fromEntries(new FormData(formRef.current));
    const newActiveToggle = formData.activeToggle;
    const garminAccount = {};
    garminAccount.activeToggle = newActiveToggle.toString();

    await setDoc(doc(db, "users", user.uid), { garminAccount }, { merge: true });
  }

  if (linkedDevice !== "Garmin") {
    return;
  }

  return (
    <form ref={formRef} className="w-full col-span-full mb-3">
      <p className="ml-9">Select which data to automatically track from your Garmin account</p>
      <div className="flex flex-wrap justify-around gap-2">
        <label className="flex gap-2 py-1 px-3 cursor-pointer" htmlFor="activityToggle">
          <input
            ref={activityToggleRef}
            name="activeToggle"
            id="activityToggle"
            type="radio"
            value="activities"
            defaultChecked={activeToggle === "activities"}
            onInput={handleInput}
          />
          <span>Intentional Activities</span>
        </label>
        <label className="flex gap-2 py-1 px-3 cursor-pointer" htmlFor="stepsToggle">
          <input
            ref={stepsToggleRef}
            name="activeToggle"
            id="stepsToggle"
            type="radio"
            value="steps"
            defaultChecked={activeToggle === "steps"}
            onInput={handleInput}
          />
          <span>Daily Steps</span>
        </label>
      </div>
      {showSaveButton && (
        <Button className="mx-auto !block px-5 mt-3" onClick={handleSave}>
          Save
        </Button>
      )}
    </form>
  );
}

function CopyToClipBoard({ text, defaultText = "Copy To Clipboard" }) {
  const [buttonText, setButtonText] = useState(defaultText);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  let copyTimeout;
  function copyToClipboard(e) {
    navigator.clipboard.writeText(text).then(
      () => {
        // console.log("copied");
        setButtonText("Copied!");
        setButtonEnabled(false);
        copyTimeout = setTimeout(() => {
          setButtonText(defaultText);
          setButtonEnabled(true);
          clearTimeout(copyTimeout);
        }, 2000);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  async function share(e) {
    if (window.navigator.canShare) {
      if (window.navigator.canShare({ url: text })) {
        try {
          await navigator.share({ url: text });
        } catch (error) {
          console.error(error.message);
        }
      }
    }
  }
  return (
    <div className="bg-[#D9D9D9] py-4 px-3 w-full flex gap-2">
      <input className="rounded-sm w-full shrink px-1" type="text" value={text} readOnly />
      <Button
        className="!text-sm !font-semibold w-max shrink-0"
        variant={buttonEnabled ? "primary" : "success"}
        onClick={copyToClipboard}
        disabled={!buttonEnabled}
      >
        <CopyIcon size={24} className="inline" />
        {buttonText}
      </Button>
      {window.navigator.canShare && (
        <Button
          className="!text-sm !font-semibold w-max shrink-0"
          variant="primary"
          onClick={share}
          // disabled={!buttonEnabled}
        >
          Share
        </Button>
      )}
    </div>
  );
}

export { RaceTitle, GarminToggle, CopyToClipBoard };
