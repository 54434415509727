import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "../../../context/UserProvider";
import { db } from "../../../firebase";

const isStaging =
  window.location.origin === "https://mcc-staging-4525c.web.app" || window.location.origin === "http://localhost:3000";


  let clientId = "104555";
  let clientSecret = "fd85a9242b08df55ace0029326f8a00596416fbb";
  let accessToken = "0160d323b0656d8e55c6e854b5501dd6f735f932";
if (isStaging) {
  clientId = "107147";
  clientSecret = "d5656afc7fc7bef8b2579f815d1023c3b7bc08ff";
  accessToken = "7c83418f8ce6ff2a4a4d98dbdf8cf9eaf136e02a";
} else {
 clientId = "104555";
 clientSecret = "fd85a9242b08df55ace0029326f8a00596416fbb";
 accessToken = "0160d323b0656d8e55c6e854b5501dd6f735f932";
}

const authUrl = "https://www.strava.com/api/v3/oauth/token";

// "https://www.strava.com/api/v3/oauth/token?client_id=104555&client_secret=fd85a9242b08df55ace0029326f8a00596416fbb&code=054ac4e0e5fa2c514bcd8849cb21f0df186f4548&grant_type=authorization_code"

const StravaConfirmation = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const scope = params.get("scope");
  const error = params.get("error");
  let message = "Loading...";

  const { user, stravaId } = useUserContext();

  if (error) {
    console.log("access denied");
    message = "Denied access!";
  }
  if (!scope || !code) {
    console.log("access denied");
    message = "404";
  }
  if (scope?.match("activity:read") && code) {
    console.log("register..");
    stravaAuthorize(code);
    message = "Connecting with strava";
  } else {
    console.log("Insufficient perms");
    message = "You must give this app permission in strava";
  }
  //   console.log(code, scope, error);
  if (stravaId) {
    message = "You are already connected!";
  }
  async function stravaAuthorize(code) {
    try {
      const response = await fetch(authUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client_id: clientId,
          client_secret: clientSecret,
          code: code,
          grant_type: "authorization_code", // your verify token
        }),
      });
      const data = await response.json();
      console.log(data);
      if (data.access_token) {
        const stravaAccount = {
          id: data.athlete?.id,
          refreshToken: data.refresh_token,
        };
        console.log(stravaAccount);
        await setDoc(doc(db, "users", user.uid), { stravaAccount }, { merge: true });
        message = "You are now connected with strava!";
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="mx-auto text-center">
      <p>StravaConfirmation</p>
      <p>{message}</p>
      <Link to="/">Go back</Link>
    </div>
  );
};

export default StravaConfirmation;
