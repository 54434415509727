import React, { useEffect, useState } from "react";
import { useUserContext } from "../../context/UserProvider";
import { useThemeContext } from "../../context/ThemeProvider";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { CopyToClipBoard } from "../util/Components";
import { Link } from "react-router-dom";

const ReferralHub = () => {
  const { user, userData } = useUserContext();
  const { colors } = useThemeContext();
  const [referralData, setReferralData] = useState();
  const referral_link = userData?.referral_link;
  const referralURL = window.location.origin + "/register?referral_link=" + referral_link;
  const rewardsMap = [
    { name: "Stickers", referralsNeeded: 1, imageURL: `${process.env.PUBLIC_URL}/img/rewards/stickers.PNG` },
    { name: "Socks", referralsNeeded: 5, imageURL: `${process.env.PUBLIC_URL}/img/rewards/socks.PNG` },
    { name: "Toque", referralsNeeded: 10, imageURL: `${process.env.PUBLIC_URL}/img/rewards/toque.PNG` },
    { name: "Free Entry", referralsNeeded: 20, imageURL: `${process.env.PUBLIC_URL}/img/rewards/free-entry.PNG` },
  ];

  let totalReferrals = 0;
  let currentReward = -1;
  let nextReward;
  if (referralData?.totals) {
    totalReferrals = Object.values(referralData?.totals).reduce((prev, raceCategory) => {
      return prev + Object.values(raceCategory).reduce((prev, legCategory) => prev + legCategory, 0);
    }, 0);
    rewardsMap.forEach((reward, index) => {
      if (totalReferrals >= reward.referralsNeeded) {
        currentReward = index;
      }
    });
  }
  if (rewardsMap[currentReward + 1]) {
    nextReward = rewardsMap[currentReward + 1];
  } else {
    nextReward = rewardsMap[currentReward];
  }
  // console.log(currentReward == rewardsMap.length - 1)

  useEffect(() => {
    async function fetchReferrers() {
      if (!referral_link) {
        return;
      }
      const referralDoc = await getDoc(doc(db, "referrals", user.uid));
      if (referralDoc.exists) {
        setReferralData(referralDoc.data());
      }
    }
    fetchReferrers();
  }, [referral_link, user]);
  return (
    <div className="max-w-5xl md:w-full pb-8 mx-auto mt-6">
      <h3>Refer your friend, you both get rewarded!</h3>
      <p className="text-sm">You earn these amazing gifts, your friend gets a $5 Discount</p>

      {/* <div className="grid grid-flow-col gap-[2vw] w-full">
        {rewardsMap.map((reward) => {
          return (
            <div
              key={reward.name}
              className="text-white font-semibold text-center w-full rounded-xl grid grid-flow-row py-2 px-1"
              style={{ backgroundColor: colors.blue }}
            >
              <img className="object-contain p-2" src={reward.imageURL} alt={`${reward.name}-image`} />
              <p className="min-w-0 m-0 whitespace-break-spaces text-sm sm:text-base">{reward.name.toUpperCase()}</p>
              <p className="m-0">{reward.referralsNeeded}</p>
            </div>
          );
        })}
      </div> */}
      <img src={`${process.env.PUBLIC_URL}/img/rewards/MCC-Referal-rewards2.jpg`} alt="Rewards" />

      {referral_link && (
        <>
          <div className="flex flex-wrap sm:flex-nowrap gap-3 my-3 items-center">
            <div className="shrink-0 h-max text-white flex items-center p-2 rounded-3xl font-medium" style={{ backgroundColor: colors.blue }}>
              <span className="mr-3 ml-2">Your Referral Count</span>
              <span className="bg-white rounded-full w-8 h-8 text-center text-base leading-8 font-bold" style={{ color: colors.blue }}>
                {totalReferrals}
              </span>
            </div>
            <span className="font-semibold">
              You're only {nextReward?.referralsNeeded - totalReferrals} referral{nextReward?.referralsNeeded - totalReferrals > 1 ? "s" : ""}{" "}
              away from winning {nextReward?.name}
            </span>
          </div>
          <div>
            <h3>Share your link</h3>
            <p>Rack up referrals by sharing your personal referral link with others:</p>
            <CopyToClipBoard text={referralURL} defaultText="Copy Link" />
          </div>
        </>
      )}
      {!referral_link && (
        <div className="mt-3">
          <p className="m-0">You don't have a referral link yet!</p>
          <p className="">Register to a race to get one.</p>
        </div>
      )}
      <div className="mt-3 text-sm text-gray-700">
        <span>By participating in the Referral Program, you agree to abide by these </span>
        <Link
          className="text-inherit"
          to="https://mychallengecanada.ca/my-challenge-canada-referral-program-terms-of-service/"
          target="_blank"
          referrerPolicy="no-referrer"
        >
          * Referral Program T&C
        </Link>
      </div>
    </div>
  );
};

export default ReferralHub;
