import React, { createContext, useContext } from "react";

const ThemeContext = createContext(null);

const ThemeProvider = ({ children }) => {
  const colors = {
    blue: "#006bc1",
    fitbitBlue: "#206276",
    fitbitBlueLight: "#00B0B9",
    stravaOrange: "#FC4C02",
    stravaOrangeDark: "HSL(18, 98%, 40%)",
    garminBlue: "#1976D2"
  };
  return (
    <>
      <style>{`
      :root{
        --bs-link-color: ${colors.blue};
        --bs-link-hover-color: ${colors.blue}FA !important;
      }
      .btn{
        border: none !important;
      }
      .btn-primary{
        background-color: ${colors.blue};
        border: none;
      }
      .btn-primary:hover{
        background-color: ${colors.blue}F0 !important;
      }
      .btn-primary:active{
        background-color: ${colors.blue}FA !important;
      }
      
      `}</style>
      <ThemeContext.Provider value={{ colors }}>{children}</ThemeContext.Provider>
    </>
  );
};

function useThemeContext() {
  return useContext(ThemeContext);
}

export { useThemeContext };
export default ThemeProvider;
