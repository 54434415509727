import React from "react";
import { useEffect } from "react";
import { useUserContext } from "../../../context/UserProvider";
import { Link } from "react-router-dom";
import Spinner from "../../util/Spinner";
import { getFunctions, httpsCallable } from "firebase/functions";
import { GarminToggle } from "../../util/Components";

const GarminAuth = () => {
  const { linkedDevice } = useUserContext();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("oauth_token");
  const verifierParam = params.get("oauth_verifier");
  const verifier = !!verifierParam && verifierParam !== "null" ? verifierParam : null;

  useEffect(() => {
    if (!verifier || linkedDevice !== "none") return;
    // Call cloud function here
    async function getAccessToken() {
      try {
        console.log("Exchanging token...");
        const exchangeAccessToken = httpsCallable(getFunctions(), "garmin-exchangeAccessToken");
        const response = await exchangeAccessToken({ oauth_token: token, oauth_verifier: verifier });
        console.log(response.data);
      } catch (err) {
        console.log(err);
      }
    }
    getAccessToken();
  }, [linkedDevice]);

  if (linkedDevice !== "none") {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center max-w-md mx-auto">
        <p>You are already connected with {linkedDevice}</p>
        {linkedDevice === "Garmin" && (
          <>
            <GarminToggle />
            <p>
              You can change this at any time in your <Link to="/account">Account Page</Link>
            </p>
          </>
        )}
        <Link to="/account">Go back</Link>
      </div>
    );
  }

  if (!verifier) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div>User denied request</div>
        <Link to="/account">Go back</Link>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <Spinner />
      <p>Connecting with garmin...</p>
    </div>
  );
};

export default GarminAuth;
