import { collection, getDocs } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { db } from "../../firebase";

export default function TeamForm({ selectedRace }) {
  const [selected, setSelected] = useState(null);
  const [teamsMap, setTeamsMap] = useState(null);
  const [teamsArray, setTeamsArray] = useState([]);
  const [isTeamCodeInvalid, setIsTeamCodeInvalid] = useState(false);
  const [newNameInvalid, setNewNameInvalid] = useState(false);
  const teamNameRef = useRef();
  const teamCodeRef = useRef();
  const newTeamNameRef = useRef();

  useEffect(() => {
    async function fetchTeams() {
      if (!selectedRace) {
        return;
      }
      const newTeamsMap = new Map();
      const newTeamsArray = [];
      const teamDocs = await getDocs(collection(db, "races", selectedRace, "teams"));
      teamDocs.forEach((team) => {
        newTeamsMap.set(team.id, team.data());
        newTeamsArray.push({ name: team.id, code: team.data().code });
        // console.log(newTeamsMap, newTeamsArray);
      });
      setTeamsMap(newTeamsMap);
      setTeamsArray(newTeamsArray);
    }
    fetchTeams();
    return () => {};
  }, [selectedRace]);

  return (
    <Form.Group className="mt-3">
      <h3>Teams</h3>
      <p>Create a new team or join an existing one.</p>
      <Form.Group className="flex flex-col gap-2">
        <Form.Check
          type="radio"
          label={<Form.Label>No, don’t add this participant to a team</Form.Label>}
          name="teamChoice"
          id="noTeam"
          value="noTeam"
          onChange={() => setSelected("")}
          required
        />
        <Form.Check
          type="radio"
          label={<Form.Label>Create a new team and add this participant</Form.Label>}
          name="teamChoice"
          id="newTeam"
          value="newTeam"
          onChange={() => setSelected("newTeam")}
          required
        />
        {selected === "newTeam" && (
          <Form.Group className="mb-3">
            <Form.Label htmlFor="teamName">Team Name</Form.Label>
            <Form.Control
              id="teamName"
              name="teamName"
              type="text"
              placeholder="My awesome team name"
              ref={newTeamNameRef}
              isInvalid={newNameInvalid}
              pattern="[\w ]+"
              title="Only letters and numbers accepted"
              required
            />
            <Form.Label htmlFor="teamCode">Create a Code</Form.Label>
            <Form.Control id="teamCode" name="teamCode" type="text" placeholder="Secret team code!" required />
          </Form.Group>
        )}
        <Form.Check
          type="radio"
          label={<Form.Label>Join a team created by someone else</Form.Label>}
          name="teamChoice"
          id="existingTeam"
          value="existingTeam"
          onChange={() => setSelected("existingTeam")}
          required
        />
        {selected === "existingTeam" && (
          <Form.Group className="mb-3">
            <Form.Label htmlFor="teamName">Select a Team</Form.Label>
            <Form.Select id="teamName" name="teamName" required ref={teamNameRef}>
              {teamsArray.map((team) => {
                return <option key={team.name}>{team.name}</option>;
              })}
            </Form.Select>
            <Form.Label htmlFor="teamCode">Team Code</Form.Label>
            <Form.Control
              id="teamCode"
              name="teamCode"
              type="text"
              placeholder="Your team's code"
              isInvalid={isTeamCodeInvalid}
              required
              ref={teamCodeRef}
              //   onInput={(e) => console.log(e.target.value, teamsMap.get(teamNameRef.current.value).code === e.target.value )}
            />
          </Form.Group>
        )}
      </Form.Group>
      <Form.Group className="mx-auto w-full my-3 pt-3">
        <Button
          size=""
          className="w-full mx-auto py-2"
          type="submit"
          onClick={(e) => {
            setIsTeamCodeInvalid(false);
            setNewNameInvalid(false);

            if (newTeamNameRef.current) {
              newTeamNameRef.current.value = newTeamNameRef.current.value.replace(/^\s+/, "").replace(/\s+$/, "");
              if (teamsMap.get(newTeamNameRef.current.value)) {
                e.preventDefault();
                setNewNameInvalid(true);
              }
            }
            if (teamCodeRef.current && teamNameRef.current) {
              if (teamsMap.get(teamNameRef.current.value)?.code !== teamCodeRef.current.value) {
                // console.log("TRIED");
                e.preventDefault();
                setIsTeamCodeInvalid(true);
              }
            }
          }}
        >
          Continue
        </Button>
      </Form.Group>
    </Form.Group>
  );
}
