import React, { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useUserContext } from "../../../context/UserProvider";
import Spinner from "../../util/Spinner";
import { Link } from "react-router-dom";

const FitbitAuth = () => {
  const { user, userData, linkedDevice } = useUserContext();
  const params = new URLSearchParams(window.location.search);
  const authorizationCode = params.get("code");
  const errorDescription = params.get("error_description");
  const [pageError, setPageError] = useState(null);
  // console.log(code);

  useEffect(() => {
    const userId = user.uid;
    if (errorDescription === "The user denied the request.") {
      setPageError("You must allow the app to work with fitbit!")
    }
    if (!userId || !authorizationCode || !!userData?.fitbitAccount || linkedDevice) {
      return;
    }
    const exchangeFitbitAuthorizationCode = httpsCallable(getFunctions(), "fitbit-exchangeAuthorizationCode");

    const fetchData = async () => {
      try {
        // Call the Cloud Function if fitbitData is not available
        const result = await exchangeFitbitAuthorizationCode({ userId, authorizationCode });
        console.log(result.data.message);
      } catch (error) {
        console.error("Error calling Fitbit Cloud Function:", error);
        setPageError(error);
      }
    };

    fetchData();
  }, [user, authorizationCode]);

  if (pageError) {
    return (
      <div className="mx-auto flex flex-col justify-center items-center my-auto h-full">
        <p>Something went wrong!</p>
        <p>{pageError}</p>
        <Link to="/account">Go back</Link>
      </div>
    );
  }

  return (
    <div className="mx-auto flex flex-col justify-center items-center my-auto h-full max-w-xl text-center">
      {userData?.fitbitAccount ? (
        <>
          <p>You are connected with fitbit!</p>
          <p className="font-medium mb-0">Important!</p>
          <p>You need to go to each of the challenges and toggle the button to post your activities on that specific challenge.</p>
          <Link to="/">Go back</Link>
        </>
      ) : (
        <>
          <Spinner />
          <p>Connecting...</p>
        </>
      )}
    </div>
  );
};

export default FitbitAuth;
