import { GoogleMap, Marker, MarkerClusterer, Polyline, useGoogleMap, useLoadScript } from "@react-google-maps/api";
import React, { useState } from "react";

const options = {
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  styles: [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "landscape",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    // {
    //   featureType: "administrative.neighborhood",
    //   elementType: "labels",
    //   stylers: [{ visibility: "off" }],
    // },
    // {
    //     featureType: "administrative.locality",
    //     elementType: "labels",
    //     stylers: [{ visibility: "off" }],
    //   },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ],
};

const libraries = ["geometry"];

// const kmlUrl = "/kml/TBCR_2023_-_Leg_1_1.kml";
const iconUrl = process.env.PUBLIC_URL + "/img/mapIcons/TBCR-map-";

function sortRacers(racersData) {
  const racersDataArray = [];
  racersData.forEach((racer, id) => {
    racersDataArray.push({ id: id, ...racer });
  });
  // console.log(racersDataArray);
  return racersDataArray.sort((a, b) => a.totalDistance - b.totalDistance);
}

function printMakersDistances(placeMarkersData, pos1, pos2, totalDistance) {
  placeMarkersData.forEach((markerdata) => {
    if (
      isPositionWithinBounds(
        markerdata.position,
        { lat: parseFloat(pos1.lat), lng: parseFloat(pos1.lng) },
        { lat: parseFloat(pos2.lat), lng: parseFloat(pos2.lng) }
      )
    ) {
      const distanceDiff = haversine_distance(
        parseFloat(pos1.lng),
        parseFloat(pos1.lat),
        parseFloat(markerdata.position.lng),
        parseFloat(markerdata.position.lat)
      );
      console.log(markerdata.name, `Marker Distance: ${((totalDistance + distanceDiff) / 1000).toFixed(4)}km`);
    }
  });
}

function isPositionWithinBounds(positionToCheck, position1, position2) {
  const { lat: latToCheck, lng: lngToCheck } = positionToCheck;
  const { lat: lat1, lng: lng1 } = position1;
  const { lat: lat2, lng: lng2 } = position2;

  let latCheck = parseFloat(latToCheck.toFixed(5));
  let lngCheck = parseFloat(lngToCheck.toFixed(5));

  const minLat = Math.min(lat1, lat2);
  const maxLat = Math.max(lat1, lat2);
  const minLng = Math.min(lng1, lng2);
  const maxLng = Math.max(lng1, lng2);

  return latCheck >= minLat && latCheck <= maxLat && lngCheck >= minLng && lngCheck <= maxLng;
}

function makeRacerMarker(racer, lat, lng, icon = undefined, cluster, zIndex = 10) {
  return (
    <Marker
      key={`marker_${racer.id}`}
      position={{ lat, lng }}
      icon={icon}
      options={{}}
      title={`${racer.firstName} ${racer?.lastName || ""}`}
      clusterer={cluster}
      // label={`${racer.firstName.slice(0, 1)}${
      //   racer?.lastName ? racer?.lastName.slice(0, 2) : racer.firstName.slice(1, 2)
      // }`}
      draggable={false}
      zIndex={zIndex}
    />
  );
}

function makeMilestoneMarker(lat, lng, index) {
  let iconPath = iconUrl;
  let title = "";
  let size = 50;
  let zIndex = 7;
  switch (index) {
    case "start":
      iconPath += "start.png";
      break;
    case "finish":
      iconPath += "end.png";
      break;
    default:
      size = 45;
      zIndex = 5;
      const milestoneIndex = index <= 9 ? `0${index}` : index;
      iconPath += `milestone${milestoneIndex}.png`;
      break;
  }

  const icon = {
    url: iconPath,
    scaledSize: new window.google.maps.Size(size, size), // scaled size
    origin: new window.google.maps.Point(-11, 1), // origin
    // anchor: new window.google.maps.Point(0, 0), // anchor
  };

  return (
    <Marker
      key={`marker_${index}`}
      position={{ lat, lng }}
      icon={icon}
      options={{}}
      title={title}
      // label={`${racer.firstName.slice(0, 1)}${
      //   racer?.lastName ? racer?.lastName.slice(0, 2) : racer.firstName.slice(1, 2)
      // }`}
      draggable={false}
      zIndex={zIndex}
    />
  );
}

function haversine_distance(lon1, lat1, lon2, lat2) {
  const toRadians = (degrees) => {
    return (degrees * Math.PI) / 180;
  };
  const earthRadius = 6371000; // Earth's radius in meters
  const dLon = toRadians(lon2 - lon1);
  const dLat = toRadians(lat2 - lat1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;
  return distance;
}

const NewLeaderBoardMap = ({ racersData, ownerData, mapName }) => {
  const [racersMarkers, setRacersMarkers] = useState([]);
  const [milestoneMarkers, setMilestoneMarkers] = useState([]);
  const [ownerProgress, setOwnerProgress] = useState(/**@type google.maps.LatLng[] */ (null));
  const [ownerMarker, setOwnerMarker] = useState(null);
  const [raceLine, setRaceLine] = useState(null);
  const [raceBounds, setRaceBounds] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCOecC0c7UOkJKJjrWrxryLgtkUbdF_AGE",
    libraries,
  });
  // const map = useGoogleMap();

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    // console.log(window.google);
    // console.log(map);
    const onLoad = async (mapInstance) => {
      // do something with map Instance
      //   console.log(mapInstance);
      // const kmlayer = new window.google.maps.KmlLayer("https://mcc-staging-4525c.web.app/kml/TBCR_2023_-_Leg_1_1.kml", {
      //   suppressInfoWindows: true,
      //   preserveViewport: false,
      //   map: mapInstance,
      //   zIndex: 100
      // });
      // setkmlLayer(kmlayer);
      // kmlayer.addListener("click", function (event) {
      //   var content = event.featureData.infoWindowHtml;
      // });
      // var infow = new window.google.maps.InfoWindow();
      // console.log(kmlayer);
      const { raceBounds } = await parseKML();
      mapInstance.fitBounds(raceBounds, 0);
    };

    const parseKML = async () => {
      // console.log("Starting parse");
      try {
        const response = await fetch(`/kml/${mapName}.kml`);
        const kmlData = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(kmlData, "text/xml");

        const lineNodes = xmlDoc.getElementsByTagName("LineString");
        const placeMarkerNodes = xmlDoc.getElementsByTagName("Placemark");

        const racersDataArray = sortRacers(racersData);
        let racerIndex = 0;

        let tempRacersMarkers = [];
        let placeMarkersData = [];
        let placeMarkersIndex = 10;
        let tempMilestonesMarkers = [];
        let coordsArray = [];
        let totalDistance = 0;
        let tempBounds = new window.google.maps.LatLngBounds();

        if (placeMarkerNodes.length > 0) {
          for (let p = 0; p < placeMarkerNodes.length; p++) {
            const placeMark = placeMarkerNodes.item(p);
            if (placeMark.getElementsByTagName("ExtendedData").length > 0) {
              const markerTitle = placeMark.getElementsByTagName("name")[0].textContent;
              const placeMarkCoordsEl = placeMark.getElementsByTagName("coordinates")[0];
              const placeMarkCoordsText = placeMarkCoordsEl.textContent.trim().replace(/\s+/g, " ");
              const [lng, lat, alt] = placeMarkCoordsText.split(",");

              let milestoneIndex = p;

              switch (markerTitle) {
                case "Start":
                  milestoneIndex = "start";
                  break;
                case "Finish":
                  milestoneIndex = "finish";
                  break;
              }

              placeMarkersData.push({ name: markerTitle, position: { lat: parseFloat(lat), lng: parseFloat(lng) } });
              tempMilestonesMarkers.push(makeMilestoneMarker(parseFloat(lat), parseFloat(lng), milestoneIndex));
            }
          }
        }

        setMilestoneMarkers(tempMilestonesMarkers);

        if (lineNodes.length > 0) {
          for (let n = 0; n < lineNodes.length; n++) {
            const line = lineNodes.item(n);
            const lineCoords = line.getElementsByTagName("coordinates")[0];
            if (lineCoords) {
              coordsArray.push(lineCoords);
            }
          }
        }

        const allCoords = coordsArray.flatMap((node) => {
          return node.textContent
            .trim()
            .replace(/\s+/g, " ")
            .split(" ")
            .map((coordString) => {
              const [lng, lat, alt] = coordString.split(",");
              return { lat: parseFloat(lat), lng: parseFloat(lng) };
            });
        });

        setRaceLine(allCoords);

        const totalCoordinates = allCoords.length;
        tempBounds.extend(allCoords[0]);

        // console.log(allCoords.length);

        // Calculate the total distance of the path
        for (let i = 1; i < totalCoordinates; i++) {
          const { lng: lng1, lat: lat1 } = allCoords[i - 1];
          const { lng: lng2, lat: lat2 } = allCoords[i];

          const distance = haversine_distance(parseFloat(lng1), parseFloat(lat1), parseFloat(lng2), parseFloat(lat2));

          // printMakersDistances(placeMarkersData, { lat: lat1, lng: lng1 }, { lat: lat2, lng: lng2 }, totalDistance);

          tempBounds.extend(allCoords[i]);

          //Check to see if a racer has gone past this distance
          if (racerIndex < racersDataArray.length) {
            const racer = racersDataArray[racerIndex];
            let racerDistance = racer.totalDistance * 1000; // * 1.02; // km to meters
            if (
              totalDistance + distance >= racerDistance ||
              racerDistance / 1000 >= parseFloat(ownerData.raceInfo.distance) ||
              (i === allCoords.length - 1 && racerDistance > totalDistance + distance)
            ) {
              // console.log(
              //   "Racer match",
              //   racer,
              //   `Racer Distance: ${racerDistance} meters\n`,
              //   `Coords index ${i} | {lat:${lat2} lng:${lng2}}\n`,
              //   `The total distance so far calculated with coords: ${totalDistance + distance} meters`
              // );

              const diff = racerDistance - totalDistance;
              const percent = diff / distance;
              // console.log(diff, percent, distance);
              let finalLat = parseFloat(lat1) + percent * (parseFloat(lat2) - parseFloat(lat1));
              let finalLng = parseFloat(lng1) + percent * (parseFloat(lng2) - parseFloat(lng1));

              // let finalLatLng = window.google.maps.geometry.spherical.interpolate(
              //   { lat: parseFloat(lat1), lng: parseFloat(lng1) },
              //   { lat: parseFloat(lat2), lng: parseFloat(lng2) },
              //   percent
              // );
              // let finalLat = finalLatLng.lat();
              // let finalLng = finalLatLng.lng();

              let hasFinished = false;

              //Set up racer Icon
              const icon = {
                url: process.env.PUBLIC_URL + "/img/mapIcons/TBCR-map-teams.png",
                scaledSize: new window.google.maps.Size(40, 40), // scaled size
                // origin: new window.google.maps.Point(0, 0), // origin
                // anchor: new window.google.maps.Point(0, 0), // anchor
              };

              if (racerDistance / 1000 >= parseFloat(ownerData.raceInfo.distance) || i === allCoords.length - 1) {
                // console.log("Racer finished!");
                const { lng: lastLng, lat: lastLat } = allCoords[allCoords.length - 1];
                finalLat = parseFloat(lastLat);
                finalLng = parseFloat(lastLng);
                hasFinished = true;
              }

              //Check to see if its our own time
              if ((ownerData.teamName !== "noTeam" && ownerData.teamName === racer.firstName) || ownerData.bibNumber === racer.bibNumber) {
                // console.log("owner found");
                icon.url = process.env.PUBLIC_URL + "/img/mapIcons/TBCR-map-you.png";
                icon.scaledSize = new window.google.maps.Size(65, 65);
                icon.origin = new window.google.maps.Point(0, -5);
                //Add progress line
                let progressArray = [];
                if (hasFinished) {
                  progressArray = allCoords;
                } else {
                  progressArray = allCoords.slice(0, i - 1);
                }
                progressArray.push({ lat: finalLat, lng: finalLng });
                setOwnerProgress(progressArray);
                setOwnerMarker(makeRacerMarker(racer, parseFloat(finalLat), parseFloat(finalLng), icon, null, 20));
              } else {
                tempRacersMarkers.push({ racer, lat: parseFloat(finalLat), lng: parseFloat(finalLng), icon });
              }

              racerIndex++;
              i--;
              continue;
            }
          }

          totalDistance += distance;
        }
        // console.log(`Distance google geometry calclulated for path: ${window.google.maps.geometry.spherical.computeLength(allCoords)}`);

        // console.log(`Total Distance calculated with harvesine formula: ${totalDistance.toFixed(2)} meters`);
        setRacersMarkers(tempRacersMarkers);
        setRaceBounds(tempBounds);

        return { raceBounds: tempBounds };
      } catch (err) {
        console.log(err.message);
      }
    };

    return (
      <GoogleMap
        mapContainerClassName="relative w-full min-h-400px h-[400px]"
        options={options}
        onLoad={onLoad}
        // zoom={10}
        // center={{ lat: 47.570846, lng: -52.697577 }} //set on load
      >
        {/* Panorama Container */}
        <div id="pano" className="isolate z-[5] w-full h-full absolute grid place-items-center bg-slate-300" style={{ display: "none" }}>
          <img id="pano-image" className="absolute inset-0 mx-auto" />
          <p className="">Street View unavailable. Keep going!</p>
        </div>

        {/* Map Buttons */}
        {ownerProgress && <StreetViewButton ownerProgress={ownerProgress} />}
        {raceBounds && <ViewAllButton bounds={raceBounds} />}
        {ownerProgress && <ViewMeButton position={ownerProgress[ownerProgress.length - 1]} />}

        {/* Racers Markers */}
        {racersMarkers && racersMarkers.length > 0 && (
          <MarkerClusterer gridSize={20} maxZoom={17}>
            {(clusterer) => {
              return racersMarkers.map((racer) => {
                return makeRacerMarker(racer.racer, racer.lat, racer.lng, racer.icon, clusterer);
              });
            }}
          </MarkerClusterer>
        )}

        {/* Owner Marker */}
        {ownerMarker && ownerMarker}

        {/* Milestone Markers */}
        {milestoneMarkers}

        {/* Race Line */}
        {raceLine && (
          <Polyline
            path={raceLine}
            options={{
              strokeColor: "#006bc1",
              strokeOpacity: 1,
              strokeWeight: 4,
              fillColor: "#FFFFFF",
              fillOpacity: 0,
              clickable: false,
              draggable: true,
              editable: false,
              visible: true,
              paths: ownerProgress,
              zIndex: 1,
            }}
          />
        )}

        {/* Progress Line */}
        {ownerProgress && (
          <Polyline
            path={ownerProgress}
            options={{
              strokeColor: "#11d846",
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: "#FFFFFF",
              fillOpacity: 0,
              clickable: false,
              draggable: true,
              editable: false,
              visible: true,
              paths: ownerProgress,
              zIndex: 2,
            }}
          />
        )}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <div>Loading...</div>;
};

export default NewLeaderBoardMap;

function ViewAllButton({ bounds }) {
  const map = useGoogleMap();
  return (
    <div
      className="absolute shadow-md right-0 p-2 bg-white m-2 rounded-sm font-semibold text-slate-600 active:text-slate-600 hover:text-slate-900 cursor-pointer"
      onClick={() => {
        if (!bounds) {
          return;
        }
        map.fitBounds(bounds);
      }}
    >
      View All
    </div>
  );
}

function ViewMeButton({ position }) {
  const map = useGoogleMap();
  return (
    <div
      className="absolute shadow-md right-0 top-12 p-2 bg-white m-2 rounded-sm font-semibold text-slate-600 active:text-slate-600 hover:text-slate-900 cursor-pointer"
      onClick={() => {
        map.panTo(position);
        // map.fitBounds(directions.routes[0].bounds);
        map.setZoom(8);
      }}
    >
      View Me
    </div>
  );
}

function StreetViewButton({ ownerProgress }) {
  const [isShown, setIsShown] = useState(false);
  const [panorama, setPanorama] = useState(null);

  // const map = useGoogleMap();
  return (
    <div
      className="absolute z-10 shadow-md right-0 p-2 top-24 bg-white m-2 rounded-sm font-semibold text-slate-600 active:text-slate-600 hover:text-slate-900 cursor-pointer"
      style={{ top: isShown ? "0" : "96px" }}
      onClick={async (e) => {
        // console.log("Retrieving panorama...");
        const panoDoc = document.getElementById("pano");

        if (!isShown) {
          panoDoc.style.display = "grid";
          const imageRef = panoDoc.getElementsByTagName("img")[0];
          if (!panorama) {
            try {
              const location = ownerProgress.at(-1);

              if (!location) {
                return;
              }
              console.log("Fetching panorama...");
              const newPanorama = new window.google.maps.StreetViewPanorama(document.getElementById("pano"), {
                position: { lat: parseFloat(location.lat), lng: parseFloat(location.lng) },
                pov: { heading: 34, pitch: 10 },
              });

              // Static Panorama
              //           const response =
              //             await fetch(`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${location.lat},${location.lng}
              // &fov=80&heading=70&pitch=0&key=AIzaSyCOecC0c7UOkJKJjrWrxryLgtkUbdF_AGE
              // `);
              //           if (!response.ok) {
              //             throw new Error(`Error getting panorama: ${response.status}`);
              //           }
              //           const imageblob = await response.blob();
              //           const objectURL = URL.createObjectURL(imageblob);
              //           imageRef.src = objectURL;

              // setPanorama(objectURL);

              setPanorama(newPanorama);
            } catch (err) {
              console.log(err.message);
            }
          } else {
            // imageRef.src = panorama;
          }
          setIsShown(true);
        } else {
          panoDoc.style.display = "none";
          setIsShown(false);
        }
      }}
    >
      {isShown ? "Close" : "Street View"}
    </div>
  );
}
