import React from "react";

const Wrapper = ({ children, width, height, color, viewBox, className }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      fill={color || "currentColor"}
      viewBox={viewBox || "0 0 48 48"}
      className={className || ""}
    >
      {children}
    </svg>
  );
};

function AccountIcon(props) {
  return (
    <Wrapper width={props?.size} height={props?.size} color={props?.color} className={props?.className}>
      <path d="M11.1 35.25q3.15-2.2 6.25-3.375Q20.45 30.7 24 30.7q3.55 0 6.675 1.175t6.275 3.375q2.2-2.7 3.125-5.45Q41 27.05 41 24q0-7.25-4.875-12.125T24 7q-7.25 0-12.125 4.875T7 24q0 3.05.95 5.8t3.15 5.45ZM24 25.5q-2.9 0-4.875-1.975T17.15 18.65q0-2.9 1.975-4.875T24 11.8q2.9 0 4.875 1.975t1.975 4.875q0 2.9-1.975 4.875T24 25.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.775t4.3-6.35q2.725-2.725 6.375-4.3Q19.9 4 24 4q4.15 0 7.775 1.575t6.35 4.3q2.725 2.725 4.3 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.3 6.375-2.725 2.725-6.35 4.3Q28.15 44 24 44Zm0-3q2.75 0 5.375-.8t5.175-2.8q-2.55-1.8-5.2-2.75-2.65-.95-5.35-.95-2.7 0-5.35.95-2.65.95-5.2 2.75 2.55 2 5.175 2.8Q21.25 41 24 41Zm0-18.5q1.7 0 2.775-1.075t1.075-2.775q0-1.7-1.075-2.775T24 14.8q-1.7 0-2.775 1.075T20.15 18.65q0 1.7 1.075 2.775T24 22.5Zm0-3.85Zm0 18.7Z" />
    </Wrapper>
  );
}

function RunnerIcon(props) {
  return (
    <Wrapper width={props?.size} height={props?.size} color={props?.color} className={props?.className}>
      <path d="M26.75 46V34.05l-5.4-4.95-2.1 9.4L6 35.75l.55-2.8 9.95 2 3.65-18.45-5 2.35v6.7h-3V16.8l8.2-3.45q1.6-.7 2.275-.875Q23.3 12.3 24 12.3q1 0 1.775.425.775.425 1.325 1.275l2.1 3.35q1.3 2.1 3.55 3.65Q35 22.55 38 22.55v3q-3.35 0-5.975-1.55-2.625-1.55-4.875-4.65l-1.95 7.9 4.55 4.2V46ZM27 10.3q-1.5 0-2.575-1.075Q23.35 8.15 23.35 6.65q0-1.5 1.075-2.575Q25.5 3 27 3q1.5 0 2.575 1.075Q30.65 5.15 30.65 6.65q0 1.5-1.075 2.575Q28.5 10.3 27 10.3Z" />
    </Wrapper>
  );
}

function DeleteIcon(props) {
  return (
    <Wrapper width={props?.size} height={props?.size} color={props?.color} className={props?.className}>
      <path d="M13.05 42q-1.25 0-2.125-.875T10.05 39V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-16.6 24.2h3V14.75h-3Zm8.3 0h3V14.75h-3Zm-13.6-24.2V39Z" />
    </Wrapper>
  );
}

function CalendarIcon(props) {
  return (
    <Wrapper width={props?.size} height={props?.size} color={props?.color} className={props?.className}>
      <path d="M9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V4h3.25v3h17V4h3.25v3H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5ZM24 28q-.85 0-1.425-.575Q22 26.85 22 26q0-.85.575-1.425Q23.15 24 24 24q.85 0 1.425.575Q26 25.15 26 26q0 .85-.575 1.425Q24.85 28 24 28Zm-8 0q-.85 0-1.425-.575Q14 26.85 14 26q0-.85.575-1.425Q15.15 24 16 24q.85 0 1.425.575Q18 25.15 18 26q0 .85-.575 1.425Q16.85 28 16 28Zm16 0q-.85 0-1.425-.575Q30 26.85 30 26q0-.85.575-1.425Q31.15 24 32 24q.85 0 1.425.575Q34 25.15 34 26q0 .85-.575 1.425Q32.85 28 32 28Zm-8 8q-.85 0-1.425-.575Q22 34.85 22 34q0-.85.575-1.425Q23.15 32 24 32q.85 0 1.425.575Q26 33.15 26 34q0 .85-.575 1.425Q24.85 36 24 36Zm-8 0q-.85 0-1.425-.575Q14 34.85 14 34q0-.85.575-1.425Q15.15 32 16 32q.85 0 1.425.575Q18 33.15 18 34q0 .85-.575 1.425Q16.85 36 16 36Zm16 0q-.85 0-1.425-.575Q30 34.85 30 34q0-.85.575-1.425Q31.15 32 32 32q.85 0 1.425.575Q34 33.15 34 34q0 .85-.575 1.425Q32.85 36 32 36Z" />
    </Wrapper>
  );
}

function SprintIcon(props) {
  return (
    <Wrapper width={props?.size} height={props?.size} color={props?.color} className={props?.className}>
      <path d="m10.8 40-2.1-2.1 20.4-20.4H22v4h-3v-7h11.15q.7 0 1.35.25.65.25 1.15.75l6 5.95q1.45 1.45 3.35 2.2 1.9.75 4 .85v3q-2.6-.1-5-.975T36.8 23.8l-2.3-2.3-5.7 5.7 4.3 4.3-12.2 7.05-1.5-2.6 8.8-5.1-4.1-4.1ZM6 26v-3h10v3Zm-4-6.5v-3h10v3Zm37.5-4q-1.45 0-2.475-1.025Q36 13.45 36 12q0-1.45 1.025-2.475Q38.05 8.5 39.5 8.5q1.45 0 2.475 1.025Q43 10.55 43 12q0 1.45-1.025 2.475Q40.95 15.5 39.5 15.5ZM6 13v-3h10v3Z" />
    </Wrapper>
  );
}
function MailIcon(props) {
  return (
    <Wrapper width={props?.size} height={props?.size} color={props?.color} className={props?.className}>
      <path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm17-15.1L7 13.75V37h34V13.75Zm0-3L40.8 11H7.25ZM7 13.75V11v26Z" />
    </Wrapper>
  );
}
function LocationIcon(props) {
  return (
    <Wrapper width={props?.size} height={props?.size} color={props?.color} className={props?.className}>
      <path d="M24 23.5q1.45 0 2.475-1.025Q27.5 21.45 27.5 20q0-1.45-1.025-2.475Q25.45 16.5 24 16.5q-1.45 0-2.475 1.025Q20.5 18.55 20.5 20q0 1.45 1.025 2.475Q22.55 23.5 24 23.5Zm0 16.55q6.65-6.05 9.825-10.975Q37 24.15 37 20.4q0-5.9-3.775-9.65T24 7q-5.45 0-9.225 3.75Q11 14.5 11 20.4q0 3.75 3.25 8.675Q17.5 34 24 40.05ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Zm0-23.6Z" />
    </Wrapper>
  );
}

function ArrowBackIcon(props) {
  return (
    <Wrapper width={props?.size} height={props?.size} color={props?.color} className={props?.className} viewBox="0 96 960 960">
      <path d="M448 606h152q13 0 21.5-8.5T630 576q0-13-8.5-21.5T600 546H448l53-53q9-9 9-21t-9-21q-9-9-21-9t-21 9L355 555q-9 9-9 21t9 21l104 104q9 9 21 9t21-9q9-9 9-21t-9-21l-53-53Zm32 370q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z" />
    </Wrapper>
  );
}

function TruckIcon(props) {
  return (
    <Wrapper width={props?.size} height={props?.size} color={props?.color} className={props?.className} viewBox="0 96 960 960">
      <path d="M224.118 895Q175 895 140.5 860.583 106 826.167 106 777H40V316q0-24 18-42t42-18h579v167h105l136 181v173h-71q0 49.167-34.382 83.583Q780.235 895 731.118 895 682 895 647.5 860.583 613 826.167 613 777H342q0 49-34.382 83.5-34.383 34.5-83.5 34.5ZM224 835q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17ZM100 717h22q17-27 43.041-43 26.041-16 58-16t58.459 16.5Q308 691 325 717h294V316H100v401Zm631 118q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm-52-204h186L754 483h-75v148ZM360 527Z" />
    </Wrapper>
  );
}

function CopyIcon(props) {
  return (
    <Wrapper width={props?.size} height={props?.size} color={props?.color} className={props?.className} viewBox="0 -960 960 960">
      <path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" />
    </Wrapper>
  );
}

export { AccountIcon, RunnerIcon, SprintIcon, DeleteIcon, CalendarIcon, MailIcon, LocationIcon, ArrowBackIcon, TruckIcon, CopyIcon };
