import { doc, onSnapshot } from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { useAuth } from "./AuthProvider";

const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState(null);
  const [userData, setuserData] = useState(null);
  const [userRaces, setUserRaces] = useState(null);
  const [stravaId, setstravaId] = useState(null);
  const [linkedDevice, setLinkedDevice] = useState(null);

  useEffect(() => {
    if (!user) return;
    const onsubUserDetails = onSnapshot(doc(db, "users", user.uid), (snap) => {
      // console.log(snap.exists(), snap.data().details);
      if (snap.exists()) {
        let updateLinkedDevice = "none";
        setUserDetails({ ...snap.data().details });
        setuserData(snap.data());

        let racesMap = new Map();
        const subRaces = snap.data().subscribedRaces;
        if (subRaces) {
          for (const [key, value] of Object.entries(subRaces)) {
            racesMap.set(key, value);
          }
        }
        setUserRaces(racesMap);
        setstravaId(snap.data().stravaAccount?.id || null);
        if (snap.data().stravaAccount) {
          updateLinkedDevice = "Strava";
        }
        if (snap.data().fitbitAccount) {
          updateLinkedDevice = "Fitbit";
        }
        if (snap.data().garminAccount) {
          updateLinkedDevice = "Garmin";
        }
        setLinkedDevice(updateLinkedDevice);
      }
    });
    // async function getUserDetails() {
    //   const savedDetails = await getDoc(doc(db, "users", user.uid));
    // }
    // getUserDetails();

    return () => {
      setUserDetails(null);
      onsubUserDetails();
    };
  }, [user]);

  return <UserContext.Provider value={{ user, userDetails, userRaces, userData, stravaId, linkedDevice }}>{children}</UserContext.Provider>;
};

function useUserContext() {
  return useContext(UserContext);
}

export default UserProvider;
export { useUserContext };
