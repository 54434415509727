import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { Link, Navigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Spinner from "./util/Spinner";
import { CopyToClipBoard } from "./util/Components";

const ReferralLandingPage = () => {
  const params = new URLSearchParams(window.location.search);
  const referral_link = params.get("referral_link");
  const [valid_link, setValidLink] = useState(null);
  const [activeRaces, setActiveRaces] = useState([]);
  const [referralName, setReferralName] = useState("");

  useEffect(() => {
    async function validateReferralLink(referral_link) {
      try {
        const referralQuery = doc(db, "referral_links", referral_link);
        const referralDoc = await getDoc(referralQuery);
        const validLink = referralDoc.exists();
        if (validLink) {
          setReferralName(referralDoc.data().name);
        }
        const racesQuery = query(collection(db, "stripe_products"), where("stripe_metadata_referralPage", "==", "true"));
        const racesDocs = await getDocs(racesQuery);
        if (!racesDocs.empty) {
          const activeRaces = racesDocs.docs.filter((race) => race.data().active).map((race) => race.data());
          setActiveRaces(activeRaces);
        }
        setValidLink(validLink);
      } catch (err) {
        console.error(err);
      }
    }
    if (referral_link) {
      validateReferralLink(referral_link);
    }
  }, []);
  if (!referral_link) {
    return <Navigate to="/" replace={true} />;
  }
  return (
    <div className="bg-[#D9D9D9] h-full -mx-4">
      {/* <img className="mx-auto max-w-2xl w-full" src={`${process.env.PUBLIC_URL}/img/referral_banner.png`} alt="Referral banner" /> */}
      <div
        className="h-16"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/TBCR-smile-faces.jpg)`,
          backgroundRepeat: "repeat-x",
          backgroundSize: "contain",
        }}
      ></div>
      <div className="mx-2">
        <div className="mx-auto max-w-2xl p-3 py-4 mt-2 bg-white rounded-md">
          {valid_link && (
            <h4 className="m-0 text-center font-medium mb-3">{referralName} has invited you to register and provided a $5 off coupon:</h4>
          )}
          {valid_link === true && <CopyToClipBoard text={"MCC60NR24YZ7"} defaultText="Copy Coupon" />}

          {valid_link === false && (
            <>
              <p className="text-center font-semibold m-0">Invalid Referral Link</p>
              <h4 className="text-center font-medium my-2 mb-3">You can still register now and get $5 off</h4>
              <CopyToClipBoard text={"MCC60NR24INV"} defaultText="Copy Coupon" />
            </>
          )}

          {valid_link !== null && false && (
            <Link to={"/register/CelebrateCanada" + window.location.search} className="border-3 rounded-md my-3 py-0 block">
              <picture>
                {/* <source srcSet={process.env.PUBLIC_URL + `/img/${selectedRace.metadata.checkoutBanner}.png`} type="image/png" /> */}
                <img
                  className="w-full max-w-lg mb-4 mt-4 mx-auto text-center text-sm"
                  src="https://mychallengecanada.ca/wp-content/uploads/2024/03/checkout-ccc-2024-1.png"
                  alt="Promotional banner for celebrate canada"
                  title="Celebrate Canada!"
                ></img>
              </picture>
            </Link>
          )}

          {valid_link === null && (
            <div>
              <Spinner />
              <h4 className="text-center">Loading</h4>
            </div>
          )}

          {valid_link !== null && (
            <div className="">
              {activeRaces.map((race) => {
                return (
                  <Link
                    className="text-black decoration-transparent"
                    key={race.metadata.url}
                    to={race.metadata.url + `?referral_link=${referral_link}`}
                  >
                    <div className="border-3 rounded-md my-3 py-2">
                      <div className="grid grid-rows-[130px,1fr] sm:grid-cols-[150px,1fr] items-center">
                        <img className="w-28 h-28 rounded-md m-auto p-1" src={race.images[0]} alt={race.name + "_banner"} />
                        <div className="sm:!mx-0 mr-auto ml-auto p-2">
                          <p className="my-0 font-bold">{race?.name}</p>
                          <p className="my-1 text-sm">
                            {race.stripe_metadata_userEndDate} - {race.stripe_metadata_userStartDate}
                          </p>
                          <Button className="!mx-auto sm:!mx-0 !block !text-sm !font-semibold">Register Here</Button>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // if (!referral_link || valid_link === false) {
  //   return <div>Invalid link</div>;
  // }
  // if (valid_link === true) {
  //   return (
  //     <div className="bg-slate-300 py-3 h-full -mx-4 px-2">
  //
  //     </div>
  //   );
  // }
  // return <div>loading..</div>;
};

export default ReferralLandingPage;
