import React, { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { useUserContext } from "../../context/UserProvider";
import DashboardPage from "./DashboardPage";
import LeaderBoardPage from "./LeaderBoardPage";
import StravaConfirmation from "./ConfirmPages/StravaConfirmation";
import FitbitAuth from "./ConfirmPages/FitbitAuth";
import GarminAuth from "./ConfirmPages/GarminAuth";
import AccountPage from "./AccountPage";
import ReferralHub from "./ReferralHub";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

// const dateOptions = {
//   month: "short",
//   day: "2-digit",
//   year: "numeric",
// };

const HomePage = () => {
  const { userDetails, userRaces } = useUserContext();
  const [racesMap, setRacesMap] = useState([]);
  const [homeBannerData, setHomeBannerData] = useState(null);
  // console.log(loading, homeBannerData);

  useEffect(() => {
    initHomeBanner();

    async function initHomeBanner() {
      const bannerDoc = await getDoc(doc(db, "options", "homeBanner"));
      const image_link = bannerDoc.data().image_link;
      const image_url = bannerDoc.data().image_url;
      const image_alt = bannerDoc.data().image_alt;

      setHomeBannerData({
        src: image_url,
        alt: image_alt,
        title: image_alt,
        href: image_link,
      });
    }
  }, []);

  useEffect(() => {
    if (userRaces) {
      let tempRacesMap = [];
      let racesArray = [];

      userRaces.forEach((race) => {
        racesArray.push(race);
      });
      racesArray.sort((a, b) => {
        const aDate = new Date(a.raceInfo.raceDateEnd);
        const bDate = new Date(b.raceInfo.raceDateEnd);
        if (aDate.getTime() !== bDate.getTime()) {
          return bDate.getTime() - aDate.getTime();
        }
        return a.raceInfo.legName > b.raceInfo.legName;
      });

      racesArray.forEach((race, i) => {
        tempRacesMap.push(
          <div key={i} className="relative flex flex-col items-center border rounded-md px-3 w-full justify-evenly">
            <div className="relative flex">
              <img className="w-[64px] mt-3" src={race.raceInfo?.imageUrl} alt={`${race.raceInfo?.name}-logo`} />
              <div className="flex flex-col">
                {new Date(race.raceInfo.raceDateEnd).getTime() < Date.now() && (
                  <>
                    <div className="bg-gray-300 px-2 absolute  top-0 left-9 rotate-12">
                      <h6 className="font-semibold m-0">CONCLUDED</h6>
                    </div>
                  </>
                )}
                {race?.completedDate && (
                  <div className="bg-green-700 px-2 absolute  top-6 left-16 rotate-12">
                    <h6 className="font-semibold m-0 text-white">FINSHER</h6>
                  </div>
                )}
              </div>
            </div>
            <p className="font-semibold text-lg mt-1 mb-0 text-center">{race.raceInfo?.name}</p>
            <p className="text-sm mb-0">
              {/* {race.raceInfo?.userStartDate} - {race.raceInfo?.userEndDate} */}
              {new Date(race.raceInfo.raceDateStart).toLocaleDateString([], { month: "long", day: "2-digit", year: "numeric" })}
              {" - "}
              {new Date(race.raceInfo.raceDateEnd).toLocaleDateString([], { month: "long", day: "2-digit", year: "numeric" })}
            </p>
            <p className="text-sm mb-2 text-center">{race.raceInfo?.legName}</p>
            {/* {race?.completedDate && <p className="text-green-700 font-medium text-center mb-2">Completed!</p>} */}
            <Link
              className="text-sm cursor-pointer mb-3"
              to={`/dashboard/${race.legId.replace(/^price_/, "")}`}
              // onClick={(e) => {
              //   e.preventDefault();
              //   // console.log(race)
              //   navigate(`/dashboard/${race.legId.replace(/^price_/, "")}`);
              // }}
            >
              My Challenge Dashboard
            </Link>
            <Link
              className="text-sm cursor-pointer mb-3"
              to={`/leaderboard/${race.legId.replace(/^price_/, "")}`}
              // onClick={(e) => {
              //   e.preventDefault();
              //   //   console.log(race)
              //   navigate(`/leaderboard/${race.legId.replace(/^price_/, "")}`);
              // }}
            >
              View LeaderBoard
            </Link>
          </div>
        );
      });
      setRacesMap((prev) => tempRacesMap);
    }
  }, [userRaces]);

  // if (loading || homeBannerData === null) {
  //   return;
  // }
  return (
    <Routes>
      <Route
        path=""
        element={
          <div className="mt-3 max-w-5xl md:w-full pb-8 mx-auto">
            <>
              <Link className="" to={homeBannerData?.href} target="_blank">
                <img
                  className="w-full h-auto mb-2 bg-slate-300 cursor-pointer"
                  src={homeBannerData?.src}
                  alt={homeBannerData?.alt}
                  title={homeBannerData?.title}
                />
              </Link>
              <h2 className="mb-3">Welcome {userDetails?.firstName}</h2>
              <div className="mt-3 grid grid-cols-[repeat(auto-fill,minmax(18rem,1fr))] gap-3 justify-items-center">{racesMap}</div>
            </>
          </div>
        }
      />
      <Route path="sconfirm" element={<StravaConfirmation />} />
      <Route path="fitbitAuth" element={<FitbitAuth />} />
      <Route path="garminAuth" element={<GarminAuth />} />

      <Route path="account" element={<AccountPage />} />
      <Route path="referralhub" element={<ReferralHub />} />

      {/* <Route path="stravaTest" element={<StravaTest />} /> */}
      {/* <Route path="fitbitDaily" element={<FitbitGet />} /> */}

      <Route path="dashboard/:raceId" element={<DashboardPage />} />
      <Route path="leaderboard/:raceId" element={<LeaderBoardPage />} />
      <Route path="*" element={<Navigate to="createaccount" replace={true} />} />
    </Routes>
  );
};

export default HomePage;
