import { Alert, Button, Form, Spinner as BsSpinner } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useFormContext } from "../hooks/useMultiStepForm";
import { onCreateUser, registerUserWithCode, writeUserDetails } from "../../func/writeFunctions";
import { useUserContext } from "../../context/UserProvider";
import Spinner from "../util/Spinner";
import { useNavigate } from "react-router-dom";

export function GiftCodesForm({ priceList, race }) {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registerError, setRegisterError] = useState(undefined);
  const { formDetails } = useFormContext();
  const [isValid, setIsValid] = useState(false);

  const codeInputRef = useRef();
  const redeemButton = useRef();

  const params = new URLSearchParams(window.location.search);
  const promo_code = params.get("promo_code");
  const [validParamCode, setValidParamCode] = useState();
  const [validPromoCodes, setValidPromoCodes] = useState();

  useEffect(() => {
    async function getCodes() {
      const validPromoCodesDoc = await getDoc(doc(db, "options", "validPromoCodes"));
      const validPromoCodes = validPromoCodesDoc.data().codes;
      setValidPromoCodes(validPromoCodes);

      if (!promo_code) return;
      if (validPromoCodes !== undefined && typeof validPromoCodes === typeof {}) {
        if (Object.keys(validPromoCodes).indexOf(promo_code) !== -1) {
          setValidParamCode(true);
        } else {
          setValidParamCode(false);
        }
      }
    }
    getCodes();
  }, []);

  async function registerWithCode(e) {
    if (codeInputRef.current) {
      const codeID = codeInputRef.current.value;
      if (!codeID) {
        return;
      }
      if (validPromoCodes !== undefined && typeof validPromoCodes === typeof {}) {
        if (Object.keys(validPromoCodes).indexOf(codeID) !== -1) {
          // console.log(redeemButton.current)
          setIsValid(true);
          redeemButton.current.style.transition = "background-color 200ms ease-in";
          redeemButton.current.classList.remove("btn-primary");
          redeemButton.current.classList.add("btn-success");
          // redeemButton.current.innerHTML = "✓"
          setTimeout(() => {
            // codeInputRef.current.value = validPromoCodes[codeID];
            const cli = document.createElement("button");
            cli.type = "submit";
            codeInputRef.current.appendChild(cli);
            cli.click();
          }, 1000);
          return;
        }
      }
      // console.log("Registering with gift code: ", codeID);

      //Validate gift code
      const codeDoc = await getDoc(doc(db, "giftcodes", codeID));
      //Cupon exists?
      if (!codeDoc.exists()) {
        // console.log("cupon doesn't exist");
        setRegisterError(true);
        return;
      }
      //Used?
      if (codeDoc.data().used === true) {
        // console.log("cupon used");
        setRegisterError(true);
        return;
      }
      //For this race?
      if (codeDoc.data().legId !== formDetails.distanceId) {
        // console.log("cupon for other race");
        setRegisterError("This coupon is not valid for this distance.");
        return;
      }

      //Register function
      const extraUserDetails = {};
      priceList.forEach((price) => {
        if (price.id === formDetails.distanceId) {
          extraUserDetails.distanceString = price.data.description;
          extraUserDetails.distancePrice = price.data.unit_amount;
        }
      });

      try {
        //Set Loading
        setRegisterError(false);
        setLoading(true);
        await onCreateUser(user);
        await writeUserDetails(user, formDetails);

        const finalFormDetails = { ...formDetails, ...extraUserDetails };
        const registerSuccess = await registerUserWithCode(user, finalFormDetails, race, codeID);

        if (registerSuccess) {
          navigate(`/register/${race.data.metadata.url}/confirmation?race=${formDetails.distanceId.replace(/^price_/, "")}`);
        } else {
          setLoading(false);
          setRegisterError(true);
        }
        // If success, redirect to confirm page
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  if (loading) {
    return (
      <div className="absolute inset-0 bg-white pt-5">
        {/* <Spinner size={150} /> */}
        <Spinner />
        <p className="font-semibold text-2xl text-center">Registering...</p>
      </div>
    );
  }

  return (
    <>
      {/* {loading && (
        <div className="absolute inset-0 bg-white pt-5">
          <Spinner size={150} />
          <p className="font-semibold text-2xl text-center">Registering...</p>
        </div>
      )} */}
      {!!promo_code && validParamCode === true && (
        <div>
          <p className="text-red-700 font-medium">Your code {promo_code} has been automatically applied</p>
          <div className="flex gap-2 max-w-sm">
            <Form.Control type="text" className="text-center w-full max-w-md mx-auto " name="" id="" defaultValue={promo_code} disabled />
            <Button variant="success" className="py-2 w-full max-w-[10ch]">
              {"✓"}
            </Button>
          </div>
        </div>
      )}
      <Form.Group>
        <h2 className="text-2xl text-left mt-3">Gift Registrations</h2>
        <Form.Group>
          <p className="font-medium">Purchase gift registrations for your friends, family or colleagues.</p>
          <p>After your purchase, you will receive a follow-up email with the instructions on how to redeem them.</p>
          {showCodeForm ? (
            <Form.Group className="flex flex-col max-w-md mx-auto">
              {priceList.map((price, index) => {
                return (
                  <Form.Group className="w-full" key={`gc_${price.id}`}>
                    <Form.Label className="w-full mb-2" htmlFor={`giftCode_${price.id}`}>
                      <p className="mb-1 font-semibold">{`${price.data.description} - ${price.data.metadata.description || ""}`}</p>
                      <Form.Control
                        type="number"
                        min={0}
                        pattern="\d+"
                        name={`giftCode_${price.id}`}
                        id={`giftCode_${price.id}`}
                        // value={0}
                        placeholder="0"
                      />
                    </Form.Label>
                  </Form.Group>
                );
              })}
              <Button type="submit" className="py-2 w-full mt-3 items-self-center">
                Continue
              </Button>
            </Form.Group>
          ) : (
            <div className="w-full grid grid-cols-2 gap-3">
              <Button className="py-2" onClick={() => setShowCodeForm(true)}>
                Yes, please!
              </Button>
              <Button variant="secondary" type="submit">
                No, thanks - Continue
              </Button>
            </div>
          )}
        </Form.Group>

        {(!promo_code || validParamCode === false) && (
          <Form.Group className="mt-5 w-full grid grid-cols-2 gap-x-3 items-center !relative">
            <h3 className="text-xl text-left mt-3 col-span-2 mb-3">Have a Gift Code?</h3>

            <div className="relative">
              <Form.Control
                ref={codeInputRef}
                className="text-center w-full max-w-md mx-auto "
                placeholder="Enter your Gift Code here"
                onFocus={(e) => setRegisterError(null)}
                isInvalid={registerError}
                isValid={isValid}
                name="promoCode"
              ></Form.Control>
              {registerError && (
                <Alert variant="danger" className="m-0 text-sm py-2 anim-card w-full max-w-md mx-auto !absolute">
                  Invalid Code. Codes are case-sensitive. {typeof registerError === typeof "" && registerError}
                </Alert>
              )}
            </div>

            <Button ref={redeemButton} className="py-2 w-full max-w-[10ch]" onClick={(e) => registerWithCode(e)}>
              {isValid ? <BsSpinner size="sm" /> : "Apply!"}
            </Button>
          </Form.Group>
        )}
      </Form.Group>
    </>
  );
}
