import React, { useState } from "react";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { useUserContext } from "../../../context/UserProvider";
import { db } from "../../../firebase";
import { DeleteIcon, RunnerIcon } from "../../util/Icons";
import { useThemeContext } from "../../../context/ThemeProvider";

export default function ActivityFeed({ activityFeed, selectedRace, getActivity, totalDistance }) {
  const { user } = useUserContext();
  const { colors } = useThemeContext();
  activityFeed = activityFeed.sort((a, b) => new Date(b?.value?.activity?.date).getTime() - new Date(a?.value?.activity?.date));
  async function deleteEntry(entry) {
    try {
      const newTotalDistance =
        totalDistance - parseFloat(entry.value.distance) > 0 ? parseFloat(totalDistance) - parseFloat(entry.value.distance) : 0;
      // if (newTotalDistance < parseFloat(selectedRace.raceInfo.distance)) {
      //   //REMOVE COMPLETED
      //   await updateDoc(doc(db, "races", selectedRace.legId, "subscribedUsers", user.uid), {
      //     [`times.${entry.key}`]: deleteField(),
      //     totalDistance: newTotalDistance,
      //     completedDate: deleteField(),
      //   });
      //   // const subscribedRaces = {};
      //   // subscribedRaces[`${selectedRace.legId}`] = {};
      //   // subscribedRaces[`${selectedRace.legId}`].completedDate = deleteField();
      //   await updateDoc(doc(db, "users", user.uid), {
      //     [`subscribedRaces.${selectedRace.legId}.completedDate`]: deleteField(),
      //   });
      // } else {

      await updateDoc(doc(db, "races", selectedRace.legId, "subscribedUsers", user.uid), {
        [`times.${entry.key}`]: deleteField(),
        totalDistance: newTotalDistance,
      });
      // }

      // await setDoc(
      //   doc(db, "races", selectedRace.legId, "subscribedUsers", user.uid),
      //   { times, totalDistance: increment(newForm.distance) },
      //   { merge: true }
      // );
      console.log("deleted");
      await getActivity();
    } catch (err) {
      console.log(err.message);
    }
  }
  return (
    <div className="flex flex-col gap-2">
      <h3 className="my-4">Activity Feed</h3>
      {activityFeed.map((entry, i) => {
        let entryDate = new Date(entry.value.activity.date);
        // entryDate.setUTCHours(8)
        // const dateOptions = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" };
        const dateOptions = { year: "numeric", month: "short", day: "numeric"};
        let color = "black";
        if (/^Fit_/.test(entry.key)) {
          color = colors.fitbitBlueLight;
          entryDate.setUTCHours(12);
        }
        if (/^S_/.test(entry.key)) color = colors.stravaOrange;
        if (/^Garmin_/.test(entry.key)) color = colors.garminBlue;

        return (
          <div key={entry.key} className={`relative shadowBox gap-2 px-2 py-1 h-13 grid grid-cols-[25px,1fr,1fr,48px] items-center `}>
            <RunnerIcon color={color} />
            <div className="">
              <p className="m-0 text-sm text-center">{entryDate.toLocaleDateString(undefined, dateOptions)}</p>
              {/* <p className="m-0">
                  {entry.value.activity.hour}:{entry.value.activity.minute} {entry.value.activity.amPm}
                </p> */}
            </div>
            <div className="flex flex-wrap gap-x-3">
              {/* <p className="m-0">Distance</p> */}
              <p className="m-0 text-sm text-right w-full">
                {parseFloat(entry.value.distance).toFixed(2)} {entry.value.distanceType}
              </p>
            </div>
            <DeleteButton entry={entry} callback={deleteEntry} />
          </div>
        );
      })}
    </div>
  );
}

function DeleteButton({ entry, callback }) {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      {showConfirm ? (
        <div className="justify-self-end  relative" >
          <button
            onClick={(e) => {
              setShowConfirm(false);
              callback(entry);
            }}
            className="anim-left !origin-right right-full absolute flex h-full bg-red-300 z-10 items-center rounded-l-md"
          >
            <p className="mx-3 mb-0">Delete?</p>
            <div className="justify-self-end h-full w-12 grid items-center pr-3 cursor-pointer active:bg-red-500">Y</div>
          </button>
          <button
            className="relative justify-self-end h-full w-12 grid items-center px-3 cursor-pointer bg-green-300 active:bg-green-500"
            onClick={(e) => {
              setShowConfirm(false);
            }}
          >
            N
          </button>
        </div>
      ) : (
        <button
          className="justify-self-end w-auto grid justify-center items-center cursor-pointer bg-red-300 active:bg-red-500 rounded-full"
          style={{boxShadow: "0px 0px 0px 2px rgb(252 165 165 / var(--tw-bg-opacity))"}}
          onClick={(e) => {
            setShowConfirm(true);
            // deleteEntry(entry.key);
          }}
        >
          <DeleteIcon  />
        </button>
      )}
    </>
  );
}
