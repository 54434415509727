import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUserContext } from "../../context/UserProvider";
import { db } from "../../firebase";
import Spinner from "../util/Spinner";

export function ConfirmationPage({ raceName }) {
  const { userData, userDetails, userRaces } = useUserContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("race");
  const [raceData, setRaceData] = useState(null);

  const selectedRace = userRaces.get(`price_${paramValue}`);
  // console.log(userRaces, paramValue, selectedRace);
  const firstName = userDetails.firstName.slice(0, 1).toUpperCase() + userDetails.firstName.slice(1, userDetails.firstName.length);

  // console.log(raceData);

  async function getCurrentRace(raceId) {
    const raceDoc = await getDoc(doc(db, "stripe_products", raceId));
    setRaceData(raceDoc.data());
  }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    fbq("track", "Purchase", { value: 71, currency: "CAD" });
  }, []);

  useEffect(() => {
    if (selectedRace) {
      getCurrentRace(selectedRace.raceId);
    }
  }, [selectedRace]);

  if (!selectedRace) {
    return (
      <div className="mt-5">
        <Spinner />
      </div>
    );
  }

  switch (raceName) {
    case "BigCanadaRunStaging":
    case "BigCanadaRun":
    case "thebigcanadarun2023":
      return <BCRConfirmation firstName={firstName} selectedRace={selectedRace} raceData={raceData} />;

    case "thebigcanadarun2024":
    case "tbcr24s":
      return <BCR2024 firstName={firstName} selectedRace={selectedRace} raceData={raceData} />;

    case "60northrun2024":
    case "60northrun":
      return <NorthRunConfirmation firstName={firstName} selectedRace={selectedRace} raceData={raceData} />;

    case "60northrun25":
      return <NorthRun2025 firstName={firstName} selectedRace={selectedRace} raceData={raceData} />;

    case "february24":
      return <ISWFC24 firstName={firstName} selectedRace={selectedRace} raceData={raceData} />;

    case "celebratecanada-c":
    case "celebratecanada-a1":
      return <CelebrateCanadaConfirmation firstName={firstName} selectedRace={selectedRace} raceData={raceData} userData={userData} />;

    default:
      return <CCConfirmation firstName={firstName} selectedRace={selectedRace} raceData={raceData} />;
  }
}

function BCR2024({ firstName, selectedRace, raceData, userData }) {
  return (
    <div className="mx-auto text-left w-full h-full mb-5">
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>{firstName} You are In!</span>
        </strong>
      </p>
      <p>Thrilled to have you officially registered for the Big Canada Run 2024! {selectedRace.raceInfo.legName}</p>
      <p>
        Get ready to embark on an unforgettable journey across Canada with your team. An exhilarating adventure filled with excitement and
        camaraderie awaits. Get set to conquer the challenge and create unforgettable memories along the way!
      </p>
      <p className="font-semibold">The challenge officially starts on Canada Day.</p>
      <p>
        You have from July 1st, 2024 to June 30th, 2025 to complete {Number(selectedRace.raceInfo.distance).toLocaleString()} kilometres with
        your team. Good luck!
      </p>
      <p>
        On July 1st, you can start posting your kilometres manually or, if you plan on posting automatically through your sports watch or
        tracking app, you can set it up today on your
        <Link to={"/dashboard/" + selectedRace.legId.replace(/^price_/, "")}> Big Canada Run Dashboard here</Link>, and your activities will
        start posting automatically on July 1st.
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>BIB, Community and Medal</span>
        </strong>
      </p>
      <ul className="list-disc">
        <li>
          <p>
            Your BIB number is {selectedRace.bibNumber}. To download the printable BIB,{" "}
            <a href={raceData?.metadata?.bib_link} target="_blank" rel="noreferrer">
              click here
            </a>
            . Print it, personalise it, and share it on your socials!
          </p>
        </li>
        <li>
          <p>
            <a href={raceData?.metadata?.fb_link} target="_blank" rel="noreferrer">
              Join the private Facebook community
            </a>
            . Your fellow participants are waiting for you with motivation, support, and lots of fun.
          </p>
        </li>
        <li>
          <p>Your medal and SWAG kit will ship Mid-Fall.</p>
        </li>
      </ul>

      {(selectedRace?.giftCodes || selectedRace?.boughtProducts) && (
        <>
          <p>
            <strong>
              <span style={{ fontSize: "19px" }}>Gift registrations and Products</span>
            </strong>
          </p>
          <ul className="list-disc flex flex-col gap-3">
            {selectedRace.giftCodes && (
              <li>
                If you purchased gift registrations, you can find them below. Additionally, you will also find them in your account settings
                page. There, you will see which codes have been redeemed and which ones are still available. Please pass the gift code to the
                intended recipient, who can then use it to register for the challenge. For guidance on how to redeem a gift registration, you
                can find detailed instructions{" "}
                <a
                  href="https://scribehow.com/shared/How_to_Use_a_Gift_Code__The_Big_Canada_Run___ODwf_4oQK-b8yNyasCz3g"
                  target="_blank"
                  rel="noreferrer"
                >
                  here.
                </a>
                <ul className="flex flex-col gap-y-1 mt-2 " style={{ listStyleType: "square" }}>
                  {Object.keys(selectedRace.giftCodes).map((code) => (
                    <li key={code} className="m-0 ">
                      <span className="font-medium">{code}</span> - Valid for: {selectedRace.giftCodes[code]}
                    </li>
                  ))}
                </ul>
              </li>
            )}
            {selectedRace.boughtProducts && Object.values(selectedRace.boughtProducts).join(" ").match(/socks/i) && (
              <li className="font-semibold">If you purchased special edition socks, you will receive them with your medal.</li>
            )}
            {selectedRace.boughtProducts && Object.values(selectedRace.boughtProducts).includes("4-Week Stronger Runner Challenge") && (
              <>
                <li className="mb-0">
                  <span className="font-semibold">4-Week Strength Training Program</span>
                  <p className="mb-0">
                    To access your 4-Week Strength Training Program workouts, click the link below and{" "}
                    <strong>
                      <em>use the password 60NR</em>
                    </strong>{" "}
                    to enter.
                  </p>
                  <p>
                    <a
                      href="https://60northrun.ca/4weeks/?fbclid=IwAR24OXyhxFw2wJfS833a_MQDZgTv-hXZLyITZ_9KV8pyOGVRJhEhHxBoW_M"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://60northrun.ca/4weeks/
                    </a>
                  </p>
                </li>
              </>
            )}
          </ul>
        </>
      )}
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Invite Your Friends and Earn Cool Rewards</span>
        </strong>
      </p>
      <p>
        To make your experience even more rewarding, we've introduced a referral program. With your unique personal link, you can invite
        friends and family to join in on the challenge.
      </p>
      <p>
        When people register using your link, you'll earn exciting rewards, and they get $5 off their registration fee. It's our way of saying
        thank you for spreading the spirit of The Big Canada Run.
      </p>
      <p>Share your link today and start building your team for this amazing journey across Canada!</p>
      <p>
        To view and copy your referral link, visit your <Link to={"/referralhub"}>Referral Hub</Link>
      </p>
      <p className="underline">
        If you need help or have any questions, please email us at <a href="mailto:race@thebigcanadarun.ca">race@thebigcanadarun.ca</a>
      </p>
      <p>Good luck with your challenge, and let's get ready to start the coveted adventure across Canada. This will be epic!</p>
    </div>
  );
}

function BCRConfirmation({ firstName, selectedRace, raceData }) {
  return (
    <div className="mx-auto text-left w-full h-full mb-5">
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>{firstName} You are In!</span>
        </strong>
      </p>
      <p>
        It is great to have you on board for this epic journey across Canada. We are looking forward to seeing you enjoy this coveted
        adventure to cross Canada with your team.
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>The challenge officially starts on Canada Day.</span>
        </strong>
      </p>
      <p>
        You have from July 1st, 2023 to June 30th, 2024 to complete {selectedRace.raceInfo.legName} {selectedRace.raceInfo.distance}Km with
        your team across Canada. Good luck!
      </p>
      <p>
        On July 1st, you can start posting your kilometres manually or sync your sports watch or tracking app today on your{" "}
        <Link to={`/dashboard/${selectedRace.legId.replace(/^price_/, "")}`}>Big Canada Run Dashboard here.</Link>
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>BIB, Community and Medal</span>
        </strong>
      </p>
      <p>
        ° Your BIB number is {selectedRace.bibNumber}. To download the printable BIB,{" "}
        <a href={raceData?.metadata?.bib_link} target="_blank" rel="noreferrer">
          click here
        </a>
        . Print it, personalize it, and share it on your socials!
      </p>
      <p>
        ° Join the private{" "}
        <a href={raceData?.metadata?.fb_link} target="_blank" rel="noreferrer">
          Facebook community
        </a>
        . Your fellow participants are waiting for you with motivation, support, and lots of fun.
      </p>
      <p>° Your medal and SWAG kit will ship Mid-Fall.</p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Gift registrations and Products</span>
        </strong>
      </p>
      <ul className="list-disc flex flex-col gap-3">
        <li>
          If you purchased gift registrations, you can find them below. Additionally, you will also find them in your account settings page.
          There, you will see which codes have been redeemed and which ones are still available.
          {selectedRace.giftCodes && (
            <ul className="flex flex-col gap-y-1 mt-2">
              {Object.keys(selectedRace.giftCodes).map((code) => (
                <p key={code} className="m-0 ">
                  <span className="font-medium">{code}</span> - Valid for: {selectedRace.giftCodes[code]}
                </p>
              ))}
            </ul>
          )}
        </li>
        <li>If you purchased special edition socks, you will receive them along with your medal in the Fall.</li>
        {selectedRace.boughtProducts && Object.values(selectedRace.boughtProducts).indexOf("101 Body-Weight Exercises") !== -1 && (
          <>
            <li className="mb-0">
              <strong>
                <span style={{ fontSize: "18px" }}>101 Body-Weight Exercises</span>
              </strong>
            </li>
            <p className="mb-0">
              To access your 101 Body-Weight Exercises workouts, click the link below and{" "}
              <strong>
                <em>use the password CCC23</em>
              </strong>{" "}
              to enter.
            </p>
            <p>
              <a href="https://bannister.coach/101-exercises-deliverable1638934j/" target="_blank" rel="noreferrer">
                https://bannister.coach/101-exercises-deliverable1638934j/
              </a>
            </p>
          </>
        )}
      </ul>

      <p>
        We are happy to help. If you need help or have any questions, please email us at{" "}
        <a href="mailto:hello@mychallengecanada.ca">hello@mychallengecanada.ca</a>
      </p>
      <p>Good luck with your challenge, and let's get ready to start the coveted adventure across Canada. This will be epic!</p>
    </div>
  );
}

function CelebrateCanadaConfirmation({ firstName, selectedRace, raceData, userData }) {
  return (
    <div className="mx-auto text-left w-full h-full mb-5">
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>{firstName} You are In!</span>
        </strong>
      </p>
      <p>
        We are looking forward to seeing you crush this challenge and celebrate Canada's birthday in an epic way! You are all set to
        participate in the {selectedRace.raceInfo.name} {selectedRace.raceInfo.legName}
      </p>
      <p>
        You have, from March 1st to December 31st, 2024, to complete the {selectedRace.raceInfo.distance} km and finish the challenge. Good
        luck!
      </p>
      <p>
        You can start posting your kilometres manually or sync your sports watch or tracking app today on your{" "}
        <Link to={"/dashboard/" + selectedRace.legId.replace(/^price_/, "")}>Celebrate Canada Dashboard here.</Link>
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>BIB, Community and Medal</span>
        </strong>
      </p>
      <ul className="list-disc">
        <li>
          <p>
            Your BIB number is {selectedRace.bibNumber}. To download the printable BIB,{" "}
            <a href={raceData?.metadata?.bib_link} target="_blank" rel="noreferrer">
              click here
            </a>
            . Print it, personalise it, and share it on your socials!
          </p>
        </li>
        <li>
          <p>
            <a href={raceData?.metadata?.fb_link} target="_blank" rel="noreferrer">
              Join the private Facebook community
            </a>
            . Your fellow participants are waiting for you with motivation, support, and lots of fun.
          </p>
        </li>
        <li>
          <p>We will ship the first batch of medals to finishers mid-June.</p>
        </li>
      </ul>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Health and Fitness Bundle</span>
        </strong>
      </p>
      <p>To access your Health and Fitness Bundle, click on the links below:</p>
      <ul className="list-disc flex flex-col gap-2">
        <li>
          <a href="https://drive.google.com/file/d/1GhoLMEpNuf48A_6805e7OGIdg2XavhG2/view?usp=sharing" target="_blank" rel="noreferrer">
            MEAL Prepping 101
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1EYXinWBidkUHDHZ1p9bTYtRss6m6EER-/view?usp=sharing" target="_blank" rel="noreferrer">
            Fit in 15 Minutes - Fit, Strong, and Healthy Busy Women
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1N8J3LKGTs2atpWHZSW7iWBJzc1YVI8Mi/view?usp=sharing" target="_blank" rel="noreferrer">
            Self-Care Monthly Planner
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1YZu-rPv6Bi_CvcRMFB5wq6CXvuDL6D3t/view?usp=sharing" target="_blank" rel="noreferrer">
            30-Day Squat Challenge
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1uFLNUjJjR-YRETGV-PKJJpagocnr4scn/view?usp=sharing" target="_blank" rel="noreferrer">
            30-Day Plank Challenge
          </a>
        </li>
      </ul>

      {(selectedRace?.giftCodes || selectedRace?.boughtProducts) && (
        <>
          <p>
            <strong>
              <span style={{ fontSize: "19px" }}>Gift registrations and Products</span>
            </strong>
          </p>
          <ul className="list-disc flex flex-col gap-3">
            {selectedRace.giftCodes && (
              <li>
                If you purchased gift registrations, you can find them below. Additionally, you will also find them in your account settings
                page. There, you will see which codes have been redeemed and which ones are still available.
                <ul className="flex flex-col gap-y-1 mt-2 " style={{ listStyleType: "square" }}>
                  {Object.keys(selectedRace.giftCodes).map((code) => (
                    <li key={code} className="m-0 ">
                      <span className="font-medium">{code}</span> - Valid for: {selectedRace.giftCodes[code]}
                    </li>
                  ))}
                </ul>
              </li>
            )}
            {selectedRace.boughtProducts && Object.values(selectedRace.boughtProducts).includes("Polar Bear - Run Socks") && (
              <li>If you purchased special edition socks, you will receive them with your medal.</li>
            )}
            {selectedRace.boughtProducts && Object.values(selectedRace.boughtProducts).includes("4-Week Stronger Runner Challenge") && (
              <>
                <li className="mb-0">
                  <span>Strength Training Program</span>
                  <p className="mb-0">
                    To access your Strength Training Program workouts, click the link below and{" "}
                    <strong>
                      <em>use the password 60NR</em>
                    </strong>{" "}
                    to enter.
                  </p>
                  <p>
                    <a
                      href="https://60northrun.ca/4weeks/?fbclid=IwAR24OXyhxFw2wJfS833a_MQDZgTv-hXZLyITZ_9KV8pyOGVRJhEhHxBoW_M"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://60northrun.ca/4weeks/
                    </a>
                  </p>
                </li>
              </>
            )}
          </ul>
        </>
      )}
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Invite Your Friends and Earn Cool Rewards</span>
        </strong>
      </p>
      <p>
        To make your experience even more rewarding, we've introduced a referral program. With your unique personal link, you can invite
        friends and family to join in on the challenge.
      </p>
      <p>
        When people register using your link, you'll earn exciting rewards, and they get $5 off their registration fee. It's our way of saying
        thank you for spreading the spirit of the 60 North Run. Share your link today and start building your team of fellow Winter Warriors!
      </p>
      <p>
        To view and copy your referral link, visit your <Link to={"/referralhub"}>Referral Hub</Link>
      </p>
      <p>
        If you need help or have any questions, please email us at <a href="mailto:hello@mychallengecanada.ca">hello@mychallengecanada.ca</a>
      </p>
      <p>Good luck with your challenge, and let's Celebrate Canada together!</p>
    </div>
  );
}

function CCConfirmation({ firstName, selectedRace, raceData }) {
  return (
    <div className="mx-auto text-left w-full h-full mb-5">
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>{firstName} You are In!</span>
        </strong>
      </p>
      <p>We are looking forward to seeing you crush this challenge and celebrate Canada's birthday in an epic way!</p>
      <p>You have, from April 15th to July 1st, 2023, to complete the 156 km and finish the challenge. Good luck!</p>
      <p>
        You can start posting your kilometres manually or sync your sports watch or tracking app today on your Celebrate Canada{" "}
        <Link to={`/dashboard/${selectedRace.legId.replace(/^price_/, "")}`}>Dashboard here.</Link>
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>BIB, Community and Medal</span>
        </strong>
      </p>
      <p>
        ° Your BIB number is {selectedRace.bibNumber}. To download the printable BIB,{" "}
        <a href={raceData?.metadata?.bib_link} target="_blank" rel="noreferrer">
          click here
        </a>
        . Print it, personalize it, and share it on your socials!
      </p>
      <p>
        ° Join the private{" "}
        <a href={raceData?.metadata?.fb_link} target="_blank" rel="noreferrer">
          Facebook community
        </a>
        . Your fellow participants are waiting for you with motivation, support, and lots of fun.
      </p>
      <p>° We will start shipping the first batch of medals early-June.</p>
      <p>
        If you need help or have any questions, please email us at <a href="mailto:hello@mychallengecanada.ca">hello@mychallengecanada.ca</a>
      </p>
      <p>Good luck with your challenge, and let's Celebrate Canada together!</p>
    </div>
  );
}

function NorthRunConfirmation({ firstName, selectedRace, raceData }) {
  return (
    <div className="mx-auto text-left w-full h-full mb-5">
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>{firstName} You are In!</span>
        </strong>
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Thank you for registering for 60° North Run 2024 </span>
        </strong>
      </p>
      <p>
        We are looking forward to seeing you beating winter and crushing this challenge. The challenge officially starts on Monday, January
        1st, 2024 and ends on Wednesday January 31st, 2023.
      </p>
      <p>
        On January 1st, you can start posting your kilometres manually or sync your sports watch or tracking app today on your{" "}
        <Link to={`/dashboard/${selectedRace.legId.replace(/^price_/, "")}`}>60 North Run Dashboard here.</Link>
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>BIB, Community and Medal</span>
        </strong>
      </p>
      <p>
        ° Your BIB number is {selectedRace.bibNumber}. To download the printable BIB,{" "}
        <a href={raceData?.metadata?.bib_link} target="_blank" rel="noreferrer">
          click here
        </a>
        . Print it, personalize it, and share it on your socials!
      </p>
      <p>
        ° Join the private{" "}
        <a href={raceData?.metadata?.fb_link} target="_blank" rel="noreferrer">
          Facebook community
        </a>
        . Your fellow participants are waiting for you with motivation, support, and lots of fun.
      </p>
      <p>° Your medal and SWAG kit will ship Mid-February.</p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Health and Fitness Bundle</span>
        </strong>
      </p>
      <p>To access your Health and Fitness Bundle, click on the links below:</p>
      <ul className="list-disc flex flex-col gap-2">
        <li>
          <a href="https://drive.google.com/file/d/1GhoLMEpNuf48A_6805e7OGIdg2XavhG2/view?usp=sharing" target="_blank" rel="noreferrer">
            MEAL Prepping 101
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1EYXinWBidkUHDHZ1p9bTYtRss6m6EER-/view?usp=sharing" target="_blank" rel="noreferrer">
            Fit in 15 Minutes - Fit, Strong, and Healthy Busy Women
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1N8J3LKGTs2atpWHZSW7iWBJzc1YVI8Mi/view?usp=sharing" target="_blank" rel="noreferrer">
            Self-Care Monthly Planner
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1YZu-rPv6Bi_CvcRMFB5wq6CXvuDL6D3t/view?usp=sharing" target="_blank" rel="noreferrer">
            30-Day Squat Challenge
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1uFLNUjJjR-YRETGV-PKJJpagocnr4scn/view?usp=sharing" target="_blank" rel="noreferrer">
            30-Day Plank Challenge
          </a>
        </li>
      </ul>
      {(selectedRace?.giftCodes || selectedRace?.boughtProducts) && (
        <>
          <p>
            <strong>
              <span style={{ fontSize: "19px" }}>Gift registrations and Products</span>
            </strong>
          </p>
          <ul className="list-disc flex flex-col gap-3">
            {selectedRace.giftCodes && (
              <li>
                If you purchased gift registrations, you can find them below. Additionally, you will also find them in your account settings
                page. There, you will see which codes have been redeemed and which ones are still available.
                <ul className="flex flex-col gap-y-1 mt-2 " style={{ listStyleType: "square" }}>
                  {Object.keys(selectedRace.giftCodes).map((code) => (
                    <li key={code} className="m-0 ">
                      <span className="font-medium">{code}</span> - Valid for: {selectedRace.giftCodes[code]}
                    </li>
                  ))}
                </ul>
              </li>
            )}
            {selectedRace.boughtProducts && Object.values(selectedRace.boughtProducts).includes("Polar Bear - Run Socks") && (
              <li>If you purchased special edition socks, you will receive them with your medal.</li>
            )}
            {selectedRace.boughtProducts && Object.values(selectedRace.boughtProducts).includes("4-Week Stronger Runner Challenge") && (
              <>
                <li className="mb-0">
                  <span>Strength Training Program</span>
                  <p className="mb-0">
                    To access your Strength Training Program workouts, click the link below and{" "}
                    <strong>
                      <em>use the password 60NR</em>
                    </strong>{" "}
                    to enter.
                  </p>
                  <p>
                    <a
                      href="https://60northrun.ca/4weeks/?fbclid=IwAR24OXyhxFw2wJfS833a_MQDZgTv-hXZLyITZ_9KV8pyOGVRJhEhHxBoW_M"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://60northrun.ca/4weeks/
                    </a>
                  </p>
                </li>
              </>
            )}
          </ul>
        </>
      )}
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Invite Your Friends and Earn Cool Rewards</span>
        </strong>
      </p>
      <p>
        To make your experience even more rewarding, we've introduced a referral program. With your unique personal link, you can invite
        friends and family to join in on the challenge.{" "}
      </p>
      <p>
        When people register using your link, you'll earn exciting rewards, and they get $5 off their registration fee. It's our way of saying
        thank you for spreading the spirit of the 60 North Run. Share your link today and start building your team of fellow Winter Warriors!
      </p>
      <p>
        You can copy your referral link and look at the rewards in the <Link to={`${process.env.PUBLIC_URL}/referralhub`}>Referral Hub</Link>
      </p>
      <p>
        If you need help or have any questions, please email us at <a href="mailto:race@60northrun.ca">race@60northrun.ca</a>
      </p>
      <p>Good luck with your challenge, and let's beat winter together as true Winter Warriors!</p>
    </div>
  );
}

function NorthRun25({ firstName, selectedRace, raceData }) {
  return (
    <div className="mx-auto text-left w-full h-full mb-5">
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>{firstName} You are In!</span>
        </strong>
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Thank you for registering for 60° North Run 2025 during the presale.</span>
        </strong>
      </p>
      <p>
        We look forward to seeing you beat winter and crush this challenge again next year. You can already see the 60 North Run 2025 in your
        homepage, but you will be able to post your kilometres starting on January 1st, 2025. In November, we will send you all the kick-off
        details.
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Your Free February Challenge</span>
        </strong>
      </p>
      <p>
        Your registration to the 60 North Run 2025 during the presale grants you
        <strong>
          <em> free access to the "It's Still Winter - February Challenge."</em>
        </strong>
      </p>
      <p>
        <span className="bg-[#FFD966]">
          Within the next two business days, <strong>you will receive an email with your giftcode to register</strong> for the February
          Challenge. In the email, you will find instructions on how to redeem it and participate for free.{" "}
        </span>
      </p>
      <p>
        Look for the email in your inbox, please also check the SPAM folder. If it is not there in the next 2 business days, please email us
        at race@60northrun.ca to request your gift code.
      </p>
      {(selectedRace?.giftCodes || selectedRace?.boughtProducts) && (
        <>
          <p>
            <strong>
              <span style={{ fontSize: "19px" }}>Gift registrations and Products</span>
            </strong>
          </p>
          <p>
            If you purchased gift registrations for a friend, you will find the codes to redeem them below, you can also find them on your{" "}
            <Link to="/account">account settings page here.</Link>{" "}
          </p>
          <p>
            <a
              href="https://scribehow.com/shared/How_to_use_a_Gift_Code__AWhSpgScR6qit0BoH5Jfnw?fbclid=IwAR2AIRFXix4qgiD5qn1dZ8tB1vHhmvc9K4a-Y_cxyfeTPbAdXZPHGHCTHtA"
              target="_blank"
              rel="noreferrer"
            >
              Here is a step-by-step to redeem a gift registration.
            </a>
          </p>
          {selectedRace.giftCodes && (
            <ul className="flex flex-col gap-y-1 mt-2">
              {Object.keys(selectedRace.giftCodes).map((code) => (
                <p key={code} className="m-0 ">
                  <span className="font-medium">{code}</span> - Valid for: {selectedRace.giftCodes[code]}
                </p>
              ))}
            </ul>
          )}
          {selectedRace.boughtProducts && Object.values(selectedRace.boughtProducts).includes("4-Week Stronger Runner Challenge") && (
            <>
              <li className="mb-0">
                <span>Strength Training Program</span>
                <p className="mb-0">
                  To access your Strength Training Program workouts, click the link below and{" "}
                  <strong>
                    <em>use the password 60NR</em>
                  </strong>{" "}
                  to enter.
                </p>
                <p>
                  <a
                    href="https://60northrun.ca/4weeks/?fbclid=IwAR24OXyhxFw2wJfS833a_MQDZgTv-hXZLyITZ_9KV8pyOGVRJhEhHxBoW_M"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://60northrun.ca/4weeks/
                  </a>
                </p>
              </li>
            </>
          )}
        </>
      )}
      <p>
        As a perk from the pre-sale you have FREE access to the 21-Day Mobility Challenge. To access your workouts{" "}
        <a href="https://thebigcanadarun.ca/21-days-mobility-challenge/" target="_blank" rel="noopener noreferrer">
          click here
        </a>{" "}
        and use the password
        <strong> TBCR2022</strong> to enter.
      </p>
      <p>
        If you need help or have any questions, please email us at <a href="mailto:race@60northrun.ca">race@60northrun.ca</a>
      </p>
      <p>Good luck with your challenge, and let's keep the Winter Warrior motivation alive!</p>
    </div>
  );
}

function ISWFC24({ firstName, selectedRace, raceData }) {
  return (
    <div className="mx-auto text-left w-full h-full mb-5">
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>{firstName} You are In!</span>
        </strong>
      </p>
      <p>We look forward to seeing you tackle this challenge and conquer winter in an epic way!</p>
      <p>
        This challenge consists of 11 trails all over Canada, adding up to 126 km. You will earn badges when you complete each trail. The
        first trail is the Auriol Trail, Kluane National Park in the Yukon, you can learn more about it{" "}
        <a href="https://60northrun.ca/auriol-trail-kluane-national-park-yukon/" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </p>
      <p>You have from February 1st 2024 and until February 29th 2024 to complete the challenge. Good luck!</p>
      <p>
        Once the challenge starts on February 1st you can post your kilometres manually or sync your sports watch or Strava account on your{" "}
        <Link to={`/dashboard/${selectedRace.legId.replace(/^price_/, "")}`}>Challenge Dashboard here</Link>.
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>BIB, Community and Medal</span>
        </strong>
      </p>
      <ul className="list-disc">
        <li>
          Your BIB number is {selectedRace.bibNumber}. To download the printable BIB,
          <a href="https://drive.google.com/file/d/1vLENozYLQsl0QPu9hqpeFlIp6mBLiXYv/view?usp=sharing" target="_blank" rel="noreferrer">
            {" "}
            click here
          </a>
          . Print it, personalise it, and share it on your socials!
        </li>
        <li>
          <a href="https://www.facebook.com/groups/850954739031652" target="_blank" rel="noreferrer">
            Join the private Facebook community
          </a>
          . Your fellow participants are waiting for you with motivation, support, and lots of fun.
        </li>
        <li>We will ship your commemorative keychain in March.</li>
      </ul>
      <p>
        If you need help or have any questions, please email us at <a href="mailto:race@60northrun.ca">race@60northrun.ca</a>
      </p>
      <p>Good luck with your challenge, and let's keep the Winter Warrior motivation alive!</p>
    </div>
  );
}

function NorthRun2025({ firstName, selectedRace, raceData }) {
  return (
    <div className="mx-auto text-left w-full h-full mb-5">
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>{firstName} You are In!</span>
        </strong>
      </p>
      <p>
        <strong>Thank you for registering for the 60° North Run 2025</strong>
      </p>
      <p>
        We are looking forward to seeing you beating winter and crushing this challenge. The challenge officially starts on Wednesday, January
        1st, 2025, and ends on Friday, January 31st, 2025.
      </p>
      <p>
        You can connect your sports watch or tracking app on your{" "}
        <Link to={`/account`} target={"_blank"} rel={"noopener noreferrer"}>
          60° North Run Account Settings page
        </Link>{" "}
        today, so your kilometres will start posting automatically on January 1st. Or, if you prefer, you can begin posting your kilometres
        manually starting January 1st.
      </p>
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>BIB, Community and Medal</span>
        </strong>
      </p>
      <ul className="list-disc">
        <li>
          <p>
            Your BIB number is {selectedRace.bibNumber}. To download the printable BIB,{" "}
            <a href={raceData?.metadata?.bib_link} target="_blank" rel="noreferrer">
              click here
            </a>
            . Print it, personalize it, and share it on your socials!
          </p>
        </li>
        <li>
          <p>
            Join the private{" "}
            <a href={raceData?.metadata?.fb_link} target="_blank" rel="noreferrer">
              Facebook community
            </a>
            . Your fellow participants are waiting for you with motivation, support, and lots of fun.
          </p>
        </li>
        <li>
          <p>Your medal and SWAG kit will ship mid-February.</p>
        </li>
      </ul>

      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Health and Fitness Bundle</span>
        </strong>
      </p>
      <p>To access your Health and Fitness Bundle, click on the links below:</p>
      <ul className="list-disc">
        <li>
          <a href="https://drive.google.com/file/d/1GhoLMEpNuf48A_6805e7OGIdg2XavhG2/view?usp=sharing" target="_blank" rel="noreferrer">
            MEAL Prepping 101
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1EYXinWBidkUHDHZ1p9bTYtRss6m6EER-/view?usp=sharing" target="_blank" rel="noreferrer">
            Fit in 15 Minutes - Fit, Strong, and Healthy Busy Women
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1N8J3LKGTs2atpWHZSW7iWBJzc1YVI8Mi/view?usp=sharing" target="_blank" rel="noreferrer">
            Self-Care Monthly Planner
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1YZu-rPv6Bi_CvcRMFB5wq6CXvuDL6D3t/view?usp=sharing" target="_blank" rel="noreferrer">
            30-Day Squat Challenge
          </a>
        </li>
        <li>
          <a href="https://drive.google.com/file/d/1uFLNUjJjR-YRETGV-PKJJpagocnr4scn/view?usp=sharing" target="_blank" rel="noreferrer">
            30-Day Plank Challenge
          </a>
        </li>
      </ul>

      {(selectedRace?.giftCodes || selectedRace?.boughtProducts) && (
        <>
          <p>
            <strong>
              <span style={{ fontSize: "19px" }}>Gift registrations and Products</span>
            </strong>
          </p>
          <ul className="list-disc flex flex-col gap-3">
            {selectedRace.giftCodes && (
              <li>
                If you purchased gift registrations, you can find them below. Additionally, you will also find them in your account settings
                page. There, you will see which codes have been redeemed and which ones are still available.
                <ul className="flex flex-col gap-y-1 mt-2 " style={{ listStyleType: "square" }}>
                  {Object.keys(selectedRace.giftCodes).map((code) => (
                    <li key={code} className="m-0 ">
                      <span className="font-medium">{code}</span> - Valid for: {selectedRace.giftCodes[code]}
                    </li>
                  ))}
                </ul>
              </li>
            )}
            {selectedRace.boughtProducts && Object.values(selectedRace.boughtProducts).includes("4-Week Stronger Runner Challenge") && (
              <>
                <li className="mb-0">
                  <span className="font-semibold">Strength Training Program</span>
                  <p className="mb-0">
                    To access your Strength Training Program workouts, click the link below and{" "}
                    <strong>
                      <em>use the password 60NR</em>
                    </strong>{" "}
                    to enter.
                  </p>
                  <p>
                    <a
                      href="https://60northrun.ca/4weeks/?fbclid=IwAR24OXyhxFw2wJfS833a_MQDZgTv-hXZLyITZ_9KV8pyOGVRJhEhHxBoW_M"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://60northrun.ca/4weeks/
                    </a>
                  </p>
                </li>
              </>
            )}
            {selectedRace.boughtProducts && Object.values(selectedRace.boughtProducts).join(" ").match(/socks/i) && (
              <li className="font-semibold">If you purchased special edition socks, you will receive them with your medal.</li>
            )}
          </ul>
        </>
      )}
      <p>
        <strong>
          <span style={{ fontSize: "19px" }}>Invite Your Friends and Earn Cool Rewards</span>
        </strong>
      </p>
      <p>
        To make your experience even more rewarding, you can be part of the referral program. With your unique personal link, you can invite
        friends and family to join in on the challenge.
      </p>
      <p>
        When people register using your link, you'll earn exciting rewards, and they get $5 off their registration fee. It's our way of saying
        thank you for spreading the spirit of the 60 North Run.
      </p>
      <p>Share your link today and start building your team of fellow Winter Warriors!</p>
      <p>
        You can copy your referral link and look at the rewards in the{" "}
        <Link to={"/referralhub"} target="_blank" rel="noopener noreferrer">
          Referral Hub
        </Link>
      </p>
      <p>
        If you need help or have any questions, please email us at <a href="mailto:race@60northrun.ca">race@60northrun.ca</a>
      </p>
      <p>Good luck with your challenge, and let's beat winter together as true Winter Warriors!</p>
      <p>
        <strong>Happy running,</strong>
      </p>
      <p>
        <strong>60 North Run Team</strong>
      </p>
    </div>
  );
}
