import { useEffect, useRef } from "react";

const Spinner = ({ size = 128 }) => {
  const spinnerRef = useRef(null);
  useEffect(() => {
    if (spinnerRef.current !== null) {
      spinnerRef.current.childNodes.forEach((node, index) => {
        if (node.style) {
          node.style.animation = "spinner-fade 1500ms infinite ease-in-out";
          node.style.animationDelay = `${index * 100}ms`;
          node.style.opacity = 0.01;
        }
      });
    }
  }, [spinnerRef]);
  return (
    <svg
      ref={spinnerRef}
      className={`w-[${size}px] aspect-square mx-auto my-4`}
      fill="black"
      id="spinner"
      viewBox="0 0 132.29166 132.29167"
      width={size}
      height={size}
    >
      <path
        d="M 235.85742,24.433594 V 96.882812 A 153.84625,153.84625 0 0 1 250,96.154297 153.84625,153.84625 0 0 1 264.14258,96.818359 V 24.433594 Z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 323.25586,36.191406 -27.70117,66.875004 a 153.84625,153.84625 0 0 1 26.13281,10.81836 l 27.69922,-66.869145 z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 399.5,80.5 -51.17773,51.17773 a 153.84625,153.84625 0 0 1 20,20 L 419.5,100.5 Z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 452.98438,150.61328 -66.86915,27.69922 a 153.84625,153.84625 0 0 1 10.81836,26.13281 l 66.875,-27.70117 z"
        transform="scale(0.26458333)"
      />
      <path
        d="M 403.18164,235.85742 A 153.84625,153.84625 0 0 1 403.8457,250 a 153.84625,153.84625 0 0 1 -0.72851,14.14258 h 72.44922 v -28.28516 z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 396.93945,295.55664 a 153.84625,153.84625 0 0 1 -10.91601,26.0918 l 66.96094,27.73828 10.82421,-26.13086 z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 368.26562,348.26562 a 153.84625,153.84625 0 0 1 -20,20 L 399.5,419.5 l 20,-20 z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 321.64844,386.02344 a 153.84625,153.84625 0 0 1 -26.0918,10.91601 l 27.69922,66.86914 26.13086,-10.82421 z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 235.85742,403.11719 v 72.44922 h 28.28516 V 403.11719 A 153.84625,153.84625 0 0 1 250,403.8457 153.84625,153.84625 0 0 1 235.85742,403.11719 Z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 178.35156,386.02344 -27.73828,66.96094 26.13086,10.82421 27.69922,-66.86914 a 153.84625,153.84625 0 0 1 -26.0918,-10.91601 z"
        transform="scale(0.26458333)"
      />
      <path
        d="M 131.73438,348.26562 80.5,399.5 l 20,20 51.23438,-51.23438 a 153.84625,153.84625 0 0 1 -20,-20 z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 103.06055,295.55664 -66.869144,27.69922 10.824219,26.13086 66.960935,-27.73828 a 153.84625,153.84625 0 0 1 -10.91601,-26.0918 z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 24.433594,235.85742 v 28.28516 H 96.882812 A 153.84625,153.84625 0 0 1 96.154297,250 153.84625,153.84625 0 0 1 96.882812,235.85742 Z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 47.015625,150.61328 -10.824219,26.13086 66.869144,27.69922 a 153.84625,153.84625 0 0 1 10.91601,-26.0918 z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 100.5,80.5 -20,20 51.23438,51.23438 a 153.84625,153.84625 0 0 1 20,-20 z"
        transform="scale(0.26458333)"
      />
      <path
        d="m 176.74414,36.191406 -26.13086,10.824219 27.73828,66.960935 a 153.84625,153.84625 0 0 1 26.0918,-10.91601 z"
        transform="scale(0.26458333)"
      />
    </svg>
  );
};
export default Spinner;
