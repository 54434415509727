import { collection, doc, getDoc, getDocs, limit, orderBy, query, startAt } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { useUserContext } from "../../../context/UserProvider";
import { db } from "../../../firebase";
import { RaceTitle } from "../../util/Components";
import { SprintIcon } from "../../util/Icons";
import { useThemeContext } from "../../../context/ThemeProvider";
import LeaderboardMap from "./LeaderboardMap";
import NewLeaderBoardMap from "./NewLeaderBoardMap";

export default function LeaderBoardPage() {
  const { colors: COLORS } = useThemeContext();
  const { user: USER, userRaces } = useUserContext();
  const { raceId } = useParams();
  const [raceUsers, setRaceUsers] = useState(new Map());
  const selectedUsersPanel = useRef();
  const myTimePanel = useRef();
  const [raceData, setRaceData] = useState(null);

  const selectedRace = userRaces?.get(`price_${raceId}`);

  let raceUsersArray = [];
  if (raceUsers) {
    raceUsers.forEach((user, id) => {
      raceUsersArray.push({ id: id, data: user });
    });
  }
  raceUsersArray = raceUsersArray.sort((a, b) => {
    let aDistance = parseFloat(a.data?.totalDistance) || 0;
    let bDistance = parseFloat(b.data?.totalDistance) || 0;
    return bDistance - aDistance;
  });

  raceUsersArray = raceUsersArray.sort((a, b) => {
    if (!a.data.completedDate && !b.data.completedDate) return 0;
    if (!a.data.completedDate) return 1;
    if (!b.data.completedDate) return -1;
    return parseFloat(a.data.completedDate) - parseFloat(b.data.completedDate);
  });

  async function getRaceData(){
    if (!selectedRace?.legId) return;
    try{
      const raceDoc = await getDoc(doc(db, "races", selectedRace.legId))
      // console.log(`Race map: ${raceDoc.data()?.map}`)
      setRaceData(raceDoc.data())
    }catch(err){
      console.log(err.message)
    }
  }

  async function getAllUsers(startAfter = 0) {
    if (!selectedRace) return;
    try {
      const raceUsersQuery = query(collection(db, "races", selectedRace.legId, "subscribedUsers"));
      const raceUsersDocs = await getDocs(raceUsersQuery);
      const raceUsersMap = new Map();

      raceUsersDocs.forEach((user) => {
        if (user.data().teamName !== "noTeam") {
          const userData = user.data();
          let teamDistance = 0;
          // console.log(raceUsersMap.get(userData.teamName));
          if (raceUsersMap.get(userData.teamName)) {
            teamDistance = raceUsersMap.get(userData.teamName).totalDistance;
          }
          const teamData = {
            firstName: userData.teamName,
            totalDistance: userData.totalDistance + teamDistance || 0,
            isTeam: true,
            bibNumber: Math.random(),
            membersCount:
              (raceUsersMap.get(userData.teamName) ? raceUsersMap.get(userData.teamName).membersCount : 0) + 1,
          }
          if(user.data().completedDate){
            teamData.completedDate = user.data().completedDate
          }
          raceUsersMap.set(userData.teamName, teamData);
        } else {
          raceUsersMap.set(user.id, user.data());
        }
      });
      // console.log(raceUsersMap);
      setRaceUsers(raceUsersMap);
    } catch (err) {
      console.log(err.message);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getRaceData();
    getAllUsers();
    return setRaceUsers(null);
  }, [selectedRace]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!myTimePanel.current) {
          return;
        }
        if (entry.isIntersecting) {
          myTimePanel.current.style.opacity = 0;
        } else {
          myTimePanel.current.style.opacity = 1;
          if (entry.boundingClientRect.bottom > 100) {
            myTimePanel.current.style.bottom = 0;
            myTimePanel.current.style.top = "auto";
          } else {
            myTimePanel.current.style.top = 0;
            myTimePanel.current.style.bottom = "auto";
          }
        }
      },
      { threshold: 0.5 }
    );

    if (selectedUsersPanel.current && myTimePanel) {
      observer.observe(selectedUsersPanel.current);
    }

    return () => {
      if (selectedUsersPanel.current) {
        observer.unobserve(selectedUsersPanel.current);
      }
    };
  }, [raceUsersArray]);

  if (!!userRaces && !selectedRace) {
    return <Navigate to="/" replace={true} />;
  }
  if (!selectedRace) {
    return;
  }

  const raceDistance = parseInt(selectedRace.raceInfo.distance);
  return (
    <div className="relative mx-auto max-w-3xl mt-6 w-full h-full">
      <RaceTitle selectedRace={selectedRace} />
      <p className="my-3">
        To post a new activity go to{" "}
        <Link to={`/dashboard/${selectedRace.legId.replace(/^price_/, "")}`}>My Challenge Dashboard {"->"}</Link>
      </p>

      {raceUsers && selectedRace && raceData?.map && (
        <NewLeaderBoardMap racersData={raceUsers} ownerData={selectedRace} mapName={raceData.map} />
      )}

      <div className="leaderboard relative">
        {raceUsersArray.map((user, index) => {
          let userDistance = parseFloat(user.data.totalDistance) || 0;
          let progress = (userDistance * 100) / raceDistance;
          const barProgress = progress <= 100 ? progress : 100;
          const isOwnTime = user.id == USER.uid;
          const isOwnTeamTime = user.data.teamName !== "noTeam" && selectedRace?.teamName === user.id;
          const style = isOwnTime || isOwnTeamTime ? { backgroundColor: `${COLORS.blue}23` } : {};
          return (
            <div key={user.data.bibNumber}>
              <div
                ref={isOwnTime || isOwnTeamTime ? selectedUsersPanel : null}
                className={`relative pb-4 border-b-2 py-1 mb-3 mx-1`}
                style={style}
                key={user.data.bibNumber}
              >
                <p className="font-semibold mb-2 px-1">
                  #{index + 1} {user.data.firstName} {user.data.lastName}{" "}
                  {user.data?.isTeam ? (
                    <span className="text-green-500 text-xs">Team of {user.data.membersCount}</span>
                  ) : (
                    <span className="text-green-500 text-xs">#{user.data.bibNumber}</span>
                  )}
                  {user.data.completedDate && (
                    <>
                      {" "}
                      <span className="m-0 text-sm font-thin">
                        {new Date(user.data.completedDate).toLocaleString()}
                      </span>{" "}
                    </>
                  )}
                </p>
                <div className="grid grid-cols-2 gap-3 px-2">
                  <div>
                    <p className="mb-0 ">Distance</p>
                    <p className="mb-1">{userDistance.toFixed(2)}Km</p>
                  </div>
                  <div>
                    <p className={"mb-0 ".concat(progress >= 100 ? "text-green-600" : "")}>
                      {progress >= 100 ? "Completed!" : "Progress"}
                    </p>
                    <p className="mb-1">% {progress.toFixed(2)}</p>
                  </div>
                </div>
                <div className="absolute bottom-1 bg-slate-100 w-full h-5">
                  <div className="abslute h-full bg-green-200" style={{ width: `${barProgress}%` }}></div>
                  <div
                    className="absolute h-full w-5 top-0  "
                    style={{ left: `clamp(0px,calc(${barProgress}% - 10px), calc(100% - 20px))` }}
                  >
                    <SprintIcon size={20} />
                  </div>
                </div>
              </div>
              {/* `SELF` */}
              {(isOwnTime || isOwnTeamTime) && (
                <div
                  ref={myTimePanel}
                  className={`fixed pointer-events-none pb-4 w-[calc(100%-2rem)] max-w-3xl border-b-2 py-1 mx-auto z-10 bottom-[env(safe-area-inset-bottom]  backdrop-blur-md`}
                  style={{ backgroundColor: `${COLORS.blue}23` }}
                  key={`myTime_${user.id}`}
                >
                  <p className="font-semibold mb-2 px-1">
                    #{index + 1} {user.data.firstName} {user.data.lastName}{" "}
                    {user.data?.isTeam ? (
                      <span className="text-green-500 text-xs">Team of {user.data.membersCount}</span>
                    ) : (
                      <span className="text-green-500 text-xs">#{user.data.bibNumber}</span>
                    )}
                    {user.data.completedDate && (
                      <>
                        {" "}
                        <span className="m-0 text-sm font-thin">
                          {new Date(user.data.completedDate).toLocaleString()}
                        </span>{" "}
                      </>
                    )}
                  </p>
                  <div className="grid grid-cols-2 gap-3 px-2">
                    <div>
                      <p className="mb-0 ">Distance</p>
                      <p className="mb-1">{userDistance.toFixed(2)}Km</p>
                    </div>
                    <div>
                      <p className={"mb-0 ".concat(progress >= 100 ? "text-green-600" : "")}>
                        {progress >= 100 ? "Completed!" : "Progress"}
                      </p>
                      <p className="mb-1">% {progress.toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="absolute bottom-1 bg-slate-100 w-full h-5">
                    <div className="abslute h-full bg-green-200" style={{ width: `${barProgress}%` }}></div>
                    <div
                      className="absolute h-full w-5 top-0  "
                      style={{ left: `clamp(0px,calc(${barProgress}% - 10px), calc(100% - 20px))` }}
                    >
                      <SprintIcon size={20} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
