import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { db } from "../firebase";

async function onCreateUser(user) {
  let docRef = await getDoc(doc(db, "users", user.uid));
  if (docRef.exists()) {
    return;
  }
  return setDoc(doc(db, "users", user.uid), {
    email: user.email,
    createdAt: serverTimestamp(),
  });
}
function writeUserDetails(user, userDetails) {
  // console.log(userDetails);
  return setDoc(
    doc(db, "users", user.uid),
    {
      agreedToTerms: true,
      email: user.email,
      details: {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        address: userDetails.address,
        country: userDetails.country,
        city: userDetails.city,
        state: userDetails.state,
        postalCode: userDetails.postalCode,
        lastModifiedAt: serverTimestamp(),
        phoneNumber: userDetails.phoneNumber,
      },
    },
    { merge: true }
  );
}

function writeUserToRace(user, userDetails) {
  return addDoc(collection(db, "races", userDetails.raceId, "users"), {
    userID: user.uid,
    email: user.email,
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    country: userDetails.country,
    distanceID: userDetails.distanceId,
    distance: userDetails.distanceString,
  });
}

async function createCheckout(user, userDetails, race, id = null, priceMap, referral_link = null) {
  const utm_params = ["utm_source", "utm_medium", "utm_campaign", "referral_link"];
  const params = new URLSearchParams(window.location.search);
  let paramsURL = "";
  utm_params.forEach((p) => {
    const value = params.get(p);
    if (value) paramsURL += `&${p}=${value}`;
  });
  // const cancelURL = id !== null ? `${window.location.origin}/register/${id}` + paramsURL.replace(/^&/, "?") : window.location.origin;
  const cancelURL = id !== null ? `${window.location.origin}/register/${id}` + window.location.search : window.location.origin;
  const successURL =
    id !== null
      ? `${window.location.origin}/register/${id}/confirmation?race=${userDetails.distanceId.replace(/^price_/, "")}` + paramsURL
      : window.location.origin;

  const cartItems = [];
  let calculatedPrice = 0;

  cartItems.push({
    price_data: {
      currency: "cad",
      unit_amount: userDetails.distancePrice,
      tax_behavior: "exclusive",
      product_data: { name: race.data.name, description: userDetails.distanceString, images: race.data.images },
    },
    quantity: 1,
  });

  calculatedPrice += userDetails.distancePrice;

  let donationsMetadata = {};
  if (userDetails.donation) {
    let donatiom_amount = parseInt(userDetails.donation);
    if (donatiom_amount > 0) {
      cartItems.push({
        price_data: {
          currency: "cad",
          unit_amount: donatiom_amount * 100,
          tax_behavior: "inclusive",
          product_data: {
            name: `Donation ${userDetails.foundation}`,
            description: `Donation to ${userDetails.foundation} Foundation`,
            metadata: {
              foundation: userDetails.foundation,
            },
            tax_code: "txcd_00000000",
          },
        },
        quantity: 1,
        // tax_rates: ["txr_1MlaxqKSwTC8CjLXOcGI7ygf"]
      });
      donationsMetadata[`donation_${userDetails.foundation}`] = donatiom_amount;
      calculatedPrice += donatiom_amount * 100;
    }
  }

  const giftCodes = Object.keys(userDetails).filter((key) => /^giftCode_/.test(key));
  let gitftCodesMetadata = {};

  if (giftCodes.length > 0) {
    giftCodes.forEach((giftCode) => {
      // cartItems.push({ price: giftCode.replace(/^giftCode_/, ""), quantity: parseInt(userDetails[giftCode]) });
      const priceID = giftCode.replace(/^giftCode_/, "");

      if (parseInt(userDetails[giftCode]) > 0) {
        cartItems.push({
          price_data: {
            currency: "cad",
            unit_amount: priceMap.get(priceID).unit_amount,
            tax_behavior: "exclusive",
            product_data: {
              name: "Gift Code",
              description: `${priceMap.get(priceID).description}`,
            },
          },
          quantity: parseInt(userDetails[giftCode]),
        });
        calculatedPrice += priceMap.get(priceID).unit_amount * parseInt(userDetails[giftCode]);
        gitftCodesMetadata[giftCode] = userDetails[giftCode];
      }
    });
  }

  const products = Object.keys(userDetails).filter((key) => /^product_/.test(key));
  let boughtProductsMetadata = {};

  await Promise.all(
    products.map(async (product) => {
      const productId = product.replace(/^product_/, "");
      cartItems.push({ price: userDetails[product], quantity: 1 });
      const priceDoc = await getDoc(doc(db, "stripe_products", productId, "prices", userDetails[product]));
      const productDoc = await getDoc(doc(db, "stripe_products", productId));
      calculatedPrice += priceDoc.data().unit_amount;
      boughtProductsMetadata[product] = productDoc.data().name;
    })
  );

  if (userDetails.country !== "Canada") {
    cartItems.push({
      price_data: {
        currency: "cad",
        unit_amount: 1500,
        tax_behavior: "exclusive",
        product_data: { name: "International Shipping" },
      },
      quantity: 1,
    });
    calculatedPrice += 1500;
  }

  // console.log("Calculated price", calculatedPrice / 100);
  let feeM = Math.floor(Math.min(calculatedPrice / 100 / 145, 5));
  // console.log("Fee mult", feeM);

  // cartItems.push({ price: userDetails.serviceFeePriceId, quantity: 1 + feeM});

  const serviceFeeDocs = await getDocs(query(collection(db, "stripe_products"), where("name", "==", "Service Fee")));
  const serviceFeeDescription = serviceFeeDocs.docs[0].data().description;
  const serviceFeePriceDoc = await getDoc(doc(db, "stripe_products", serviceFeeDocs.docs[0].id, "prices", userDetails.serviceFeePriceId));

  cartItems.push({
    price_data: {
      currency: "cad",
      unit_amount: serviceFeePriceDoc.data().unit_amount * (feeM + 1),
      tax_behavior: "exclusive",
      product_data: {
        name: "Service Fee",
        description: serviceFeeDescription,
      },
    },
    quantity: 1,
  });

  // const items = [
  //   { price_data: { currency: "cad", unit_amount: 2000, product_data: { name: "test" } }, quantity: 1 },
  //   { price: userDetails.serviceFeePriceId, quantity: 1 },
  //   { price: userDetails.distanceId, quantity: 1 },
  //   { price_data: { currency: "cad", unit_amount: 1000, product_data: { name: "test1" } }, quantity: 1 },
  //   { price_data: { currency: "cad", unit_amount: 1000, product_data: { name: "test1" } }, quantity: 1 },
  // ];

  // console.log("cartItems", cartItems);

  // Checkout metadata
  const metaData = {
    teamChoice: userDetails?.teamChoice || "noTeam",
    raceId: race.id,
    legId: userDetails.distanceId,
    raceDateStart: race.data.metadata.raceStartDate,
    raceDateEnd: race.data.metadata.raceEndDate,
    raceImageUrl: race.data.images[0] || "",
    legName: userDetails.distanceString,
    raceName: race.data.name,
    teamName: userDetails?.teamName || "noTeam",
    teamCode: userDetails?.teamCode || "",
    userStartDate: race.data.metadata.userStartDate || "",
    userEndDate: race.data.metadata.userEndDate || "",
    ...gitftCodesMetadata,
    ...boughtProductsMetadata,
    ...donationsMetadata,
  };

  // Referral Logic
  if (referral_link !== null && typeof referral_link === typeof "") {
    // Check if referral code exists (again)?
    const validReferralDoc = await getDoc(doc(db, "referral_links", referral_link));
    const validReferralLink = validReferralDoc.exists();
    if (validReferralLink) {
      metaData.referral_link = referral_link;
    }
    // metaData.referral_link = referral_link
  }

  // if (Object.keys(gitftCodesMetadata).length > 0) {
  //   metaData.giftCodes = gitftCodesMetadata;
  // }

  const checkoutData = {
    mode: "payment",
    automatic_tax: true,
    line_items: cartItems,
    metadata: metaData,
    success_url: successURL,
    cancel_url: cancelURL,
    allow_promotion_codes: true,
  };
  if (userDetails?.promoCode) {
    const validPromoCodesDoc = await getDoc(doc(db, "options", "validPromoCodes"));
    const validPromoCodes = validPromoCodesDoc.data().codes;
    if (Object.keys(validPromoCodes).indexOf(userDetails?.promoCode) !== -1) {
      checkoutData.promotion_code = validPromoCodes[userDetails.promoCode];
    }
  }

  const checkoutDocRef = await addDoc(collection(db, "stripe_customers", user.uid, "checkout_sessions"), checkoutData);

  let checkoutError = false;
  await new Promise((resolve, reject) => {
    const onCheckoutSession = onSnapshot(checkoutDocRef, (snap) => {
      const { url, error } = snap.data();
      if (error) {
        onCheckoutSession();
        // console.log(snap.data().error);
        checkoutError = snap.data().error.message;
        resolve();
      }
      if (url) {
        onCheckoutSession();
        window.open(snap.data().url, "_self");
        resolve();
      }
    });
  });
  return checkoutError;
}

async function registerUserWithCode(user, userDetails, race, cuponID) {
  // console.log("USER", user);
  // console.log("DETAILS", userDetails);
  // console.log("RACE", race);

  const metadata = {
    teamChoice: userDetails?.teamChoice || "noTeam",
    raceId: race.id,
    legId: userDetails.distanceId,
    raceDateStart: race.data.metadata.raceStartDate,
    raceDateEnd: race.data.metadata.raceEndDate,
    raceImageUrl: race.data.images[0] || "",
    legName: userDetails.distanceString,
    raceName: race.data.name,
    teamName: userDetails?.teamName || "noTeam",
    teamCode: userDetails?.teamCode || "",
    userStartDate: race.data.metadata.userStartDate || "",
    userEndDate: race.data.metadata.userEndDate || "",
  };

  try {
    //Write payment
    await addDoc(collection(db, "stripe_customers", user.uid, "payments"), { metadata, status: "succeeded" });
    // Update cupon
    await setDoc(doc(db, "giftcodes", cuponID), { used: true, usedBy: { email: user.email, id: user.uid } }, { merge: true });
    return true;
  } catch (err) {
    console.log(err.message);
  }
  return false;

  //return true on success / false on failure
}

export { writeUserDetails, writeUserToRace, onCreateUser, createCheckout, registerUserWithCode };
